import {
    UPDATE_GPS_DATA,
    SET_UPDATE_IN_PROGRESS,
    SERVER_COM_FAILED,
} from '../actions/gpsArchive'

const initialState = {
    updateInProgress: false,
    gpsData: {},
    /* inside gpsData:
        {
            _device_id: {days}
        }
    */
}

export function gpsArchiveReducer(state=initialState, action) {
    switch(action.type) {
        case UPDATE_GPS_DATA:
            const {device_id, range_data, day, day_data} = action.payload
            let newGpsData = null
            if (day) {
                const thisDevice = state.gpsData[device_id]
                const newData = {...thisDevice, [day]: day_data}
                newGpsData = {...state.gpsData, [device_id]: newData}
            } else {
                newGpsData = {...state.gpsData, [device_id]: range_data}
            }
            return {
                ...state,
                updateInProgress: false,
                gpsData: newGpsData,
            }

        case SET_UPDATE_IN_PROGRESS:
            return {
                ...state,
                updateInProgress: true,
            }
        case SERVER_COM_FAILED:
            return {
                ...state,
                updateInProgress: false,
            }
        default:
            return state
    }
}

export const selectGpsArchiveStore = state => state.gpsArchive
export const selectGpsData = state => selectGpsArchiveStore(state).gpsData
export const selectGpsDataById = id => state => selectGpsData(state)[id]