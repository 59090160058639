import React from 'react'
import {connect} from 'react-redux'
// import L from 'leaflet'
import {
    Map,
    TileLayer,
    // Marker,
    // Popup,
    // Polyline,
    // CircleMarker,
    // Circle,
    // Tooltip,
    // useLeaflet
} from 'react-leaflet'

import {AreasOnMap} from './AreasOnMap'
import {Kayaks, KayakPath} from './KayaksOnMap'

// REDUX ACTIONS
import {setMapObject} from "../redux/actions/mapActions"

class MapComponent extends React.Component {
    constructor() {
      super()
      this.mapRef = null
      this.state = {
        lat: 54.32,
        lng: 18.6,
        zoom: 11,
        mapRef: null,
      }
    }

    componentDidMount() {
        this.props.setMapObject(this.mapRef)
    }

    render() {
        const position = [this.state.lat, this.state.lng];
        const {
            showLiveDevices,
            showArchivalGpsPoints,
            archivalGpsSpec,
        } = this.props.map
        const {device, day, points} = archivalGpsSpec

        return (
            <Map
                center={position}
                zoom={this.state.zoom}
                // ref={ref => {this.props.setMapObject(ref)}}
                ref={ref => this.mapRef = ref}
                

            >
                <TileLayer
                    attribution='© <a href="https://www.mapbox.com/about/maps/">Mapbox</a> © <a href="http://www.openstreetmap.org/copyright">OpenStreetMap</a> <strong><a href="https://www.mapbox.com/map-feedback/" target="_blank">Improve this map</a></strong>'
                    // url='https://api.tiles.mapbox.com/v4/{id}/{z}/{x}/{y}.png?access_token=pk.eyJ1IjoiYWRhbWxvZ2ciLCJhIjoiY2sycnB6ZGhmMGFhNDNtcDV5bmM5cnQwNCJ9.3lhMUQhlY1m3ZK5LK2xJlg'
                    // url="https://api.mapbox.com/v4/mapbox.satellite/1/0/0@2x.jpg90?access_token=pk.eyJ1IjoiYWRhbWxvZ2ciLCJhIjoiY2sycnB6ZGhmMGFhNDNtcDV5bmM5cnQwNCJ9.3lhMUQhlY1m3ZK5LK2xJlg"
                    url='https://api.mapbox.com/styles/v1/{id}/tiles/{z}/{x}/{y}?access_token={accessToken}'
                    tileSize={512}
                    id='mapbox/streets-v11'
                    zoomOffset={-1}
                    accessToken='pk.eyJ1IjoiYWRhbWxvZ2ciLCJhIjoiY2sycnB6ZGhmMGFhNDNtcDV5bmM5cnQwNCJ9.3lhMUQhlY1m3ZK5LK2xJlg'
                />
                {showLiveDevices && <Kayaks/>}

                <AreasOnMap />

                {showArchivalGpsPoints
                    && <KayakPath device={device} measurements={points} focusOnMapWhenMeasurementsLoaded={true}/>
                }
            </Map>
        )
    }
}

function mapStateToProps(state) {
    return {
        measurements: state.measurements,
        ui: state.ui,
        map: state.map,
    }
} 

const mapDispatchToProps = {
    setMapObject,
}

export default connect(mapStateToProps, mapDispatchToProps)(MapComponent)