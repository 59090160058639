import {
    GET_GPS_DATA_FOR_DEVICE,
    UPDATE_GPS_DATA,
    SERVER_COM_FAILED,
    SET_UPDATE_IN_PROGRESS,
} from '../actions/gpsArchive'
import {
    getRequest,
    postRequest,
    deleteRequest,
    putRequest,
    API_REQUEST_FAILED_ASK_USER_FOR_RETRY,
} from '../actions/apiActions'

const GPS_DATA_URL = 'gps_archive'

const getGpsDataProcess = ({dispatch}) => next => action => {
    next(action)
    
    if (action.type === GET_GPS_DATA_FOR_DEVICE) {
        
        const params = {
            data: {
                device: action.payload.id,
                day: action.payload.day,                    
            }
        }
        dispatch(postRequest(GPS_DATA_URL, params, UPDATE_GPS_DATA, SERVER_COM_FAILED))
    }
}

export const gpsArchiveMiddleware = [
    getGpsDataProcess,
]