import React, {useState, useEffect, useRef} from 'react'
import {useSelector, useDispatch} from 'react-redux'

import {selectDevicesArray, selectDeviceById} from '../redux/reducers/devicesReducer'
import {selectReportsArchiveStore, selectReportsDataById} from '../redux/reducers/reportsArchiveReducer'
import {getReportsDataForDevice, SET_UPDATE_IN_PROGRESS} from '../redux/actions/reportsArchiveActions'
import {reportAppError} from '../redux/actions/errorActions'

import {Device} from './Devices'
import {DeviceStatistics} from './DeviceStatistics'
import {FlexCol, FlexBar} from '../SmallComponents/flexBar'
import * as Buttons from '../IconComponents/Buttons'
import { Toolbar } from '../IconComponents/IconBar'


function DayStatsElement({reload, device, data, day, showReportsCallback}) {
    const [reportsCount, earliestDate, latestDate, reports] = data

    function showReports() {
        showReportsCallback(day, reports)
    }

    return (
        <div className='device-element'>
            <FlexBar>
                {day} - {reportsCount} Reports:
                <button onClick={reload}>Download</button>
            </FlexBar>
            <FlexBar>
                <div>
                    From: {earliestDate}
                    <br/>
                    To: {latestDate}
                </div>
                <button disabled={! reports || reports.length === 0} onClick={showReports}>Show</button>
            </FlexBar>
        </div>
    )
}

export function ReportsTable({reportTableData, archiveComponent}) {
    const {day, reports} = reportTableData
    const [chosenReport, setChoosenReport] = useState(0)
    const dispatch = useDispatch()

    useEffect(() => {
        setChoosenReport(0)
    }, [reportTableData])

    let reportsComps = []
    let deviceData = {}
    let timestamp_sent = '-'
    if (reports.length > 0) {
        const thisReport = reports[chosenReport]
        if (thisReport) {
            const {report} = thisReport
            timestamp_sent = reports[chosenReport].timestamp_sent
                if (report) {
                    let deviceStatsObj = null
                    try {
                        deviceStatsObj = JSON.parse(report)
                        deviceData.last_report = {}
                        deviceData.last_report.report = deviceStatsObj
                    } catch (error) {
                        reportsComps.push(<div>JSON Parsing error!</div>)
                        // dispatch(reportAppError(error, 'Parsing device statistics in Device component (most likely json error).'))
                    }
                    reportsComps = Object.entries(deviceStatsObj).map((data, id) => {
                        const [name, section] = data
                        return <div className='device-element'><DeviceStatistics key={id} deviceStatsObj={{[name]: section}} noLine={true} /></div>
                    })
                    
                    // reportsComps.push((
                    //     <div className='device-element'><DeviceStatistics key={chosenReport} deviceStatsObj={deviceStatsObj} /></div>
                    // ))
                }
            }

        // const stepp = Math.ceil(reports.length / step)
        // for (let i = 0; i < reports.length; i+=stepp) {
        //     const {device_statistics: deviceStatistics} = reports[i]
        //     if (deviceStatistics) {
        //         let deviceStatsObj = null
        //         try {
        //             deviceStatsObj = JSON.parse(deviceStatistics)
        //         } catch (error) {
        //             reportsComps.push(<div>JSON Parsing error!</div>)
        //             // dispatch(reportAppError(error, 'Parsing device statistics in Device component (most likely json error).'))
        //         }
        //         reportsComps.push((
        //             <div className='device-element'><DeviceStatistics key={i} deviceStatsObj={deviceStatsObj} /></div>
        //         ))
        //     }
        // }
    }

    function incrementChosenReport(val) {
        setChoosenReport(p => {
            const newIndex = p + val
            if (newIndex < 0) {
                return reports.length - 1
            }
            if (newIndex + 1 > reports.length) {
                return 0
            }
            return newIndex
        })
    }

    return (
        <FlexCol style={{justifyContent: 'flex-start', alignItems: 'stretch', background: 'white', padding: '5px 12px'}}> 
        {/* <div style={{flexGrow: 1, margin: '5px 12px' }}> */}
                <FlexBar style={{alignItems: 'space-between', fontSize: '1.25em'}}>
                    {/* <FlexBar> */}
                        <span>
                            {`Day: ${day || '-'}`}
                        </span>
                        <span>
                            {`Date: ${timestamp_sent}`}
                        </span>
                        <span>
                            {`Report: ${chosenReport + 1} / ${reports.length}`}
                        </span>
                    {/* </FlexBar> */}
                    <div>
                        <Buttons.ArrowLeft action={() => incrementChosenReport(-1)} />
                        <Buttons.ArrowRight action={() => incrementChosenReport(1)} />
                    </div> 
                </FlexBar> 
                <input style={{width: '100%'}} type='range' value={chosenReport} onChange={event => setChoosenReport(+event.target.value)} max={reports.length - 1} />
                <FlexBar style={{flexGrow: 1, justifyContent: 'flex-start', alignItems: 'flex-start'}}>
                    {archiveComponent}
                    <div style={{margin: '5px, 12px', flexGrow: 1}}>
                        <Device data={deviceData}/>
                        <div className='tri-column-grid'>
                            {reportsComps}
                        </div>
                    </div>
                </FlexBar>
            {/* {FIXME when the day is changed - the input range can be to big
            when a device is changed - clear displayed reports} */}            
        {/* </div> */}
        </FlexCol>
    )
}

export function ReportsArchive({deviceId}) {
    const selectedDeviceObj = useSelector(selectDeviceById(deviceId))
    const selectedDevice = deviceId
    const {updateInProgress} = useSelector(selectReportsArchiveStore)
    const reportsDataForDevice = useSelector(selectReportsDataById(selectedDevice))
    const [reportTableData, setReportTableData] = useState({day: null, reports: []})

    const dispatch = useDispatch()


    function updatePointsFromServer() {
        dispatch({type: SET_UPDATE_IN_PROGRESS})
        dispatch(getReportsDataForDevice(selectedDevice))
    }

    function updatePointsForDay(day) {
        dispatch({type: SET_UPDATE_IN_PROGRESS})
        dispatch(getReportsDataForDevice(selectedDevice, day))
    }

    function showReportsCallback(day, reports) {
        setReportTableData({day, reports})
    }

    useEffect(() => {
        if (selectedDevice !== '-' && ! updateInProgress && ! reportsDataForDevice) {
            updatePointsFromServer()
        }
    }, [selectedDevice, reportsDataForDevice])

    let reportsDataByDays = null
    if (reportsDataForDevice) {
        reportsDataByDays = Object.entries(reportsDataForDevice).map(([day, data]) => (
            <DayStatsElement
                showReportsCallback={showReportsCallback}
                reload={() => updatePointsForDay(day)}
                device={selectedDevice}
                day={day}
                data={data}
            />
        ))
    }

    const archiveComponent = (
        <div style={{background: 'white', margin: '5px, 12px'}}>
            <FlexBar>
                <h2>Archive of device reports.</h2>
                <Buttons.Refresh action={updatePointsFromServer} rotate={updateInProgress}/>
            </FlexBar>
            {selectedDevice !== '-'
                ? <Device data={selectedDeviceObj}/>
                : null
            }
            {updateInProgress ? null : reportsDataByDays}
        </div>
    )

    return (
        <ReportsTable reportTableData={reportTableData} archiveComponent={archiveComponent} />
    )
}