import axios from 'axios'
import Cookies from 'js-cookie'

import {saveRequestDuration} from '../actions/apiActions'
import {reportServerError} from '../actions/errorActions'

import {
    API_GET_REQUEST,
    API_POST_REQUEST,
    API_PUT_REQUEST,
    API_DELETE_REQUEST,
    API_REQUEST_FAILED_ASK_USER_FOR_RETRY,
} from "../actions/apiActions"
const ALL_REQUESTS = [
    API_GET_REQUEST,
    API_POST_REQUEST,
    API_PUT_REQUEST,
    API_DELETE_REQUEST,
]

let cookieToken = Cookies.get('csrftoken')
// axios.defaults.xsrfCookieName = 'csrftoken'
// axios.defaults.xsrfHeaderName = "X-CSRFtoken"
axios.defaults.headers.common['X-CSRFToken'] = cookieToken

// const SERVER_URL = `http://localhost:8000/api/`
const SERVER_URL = `https://kayak.devtracker.pl/api/`

// axios.interceptors.request.use(config => {
//     // log a message before any HTTP request is sent
//     console.log('Request was sent');
//     config.headers = {
//         ...config.headers,
//         'X-CSRFTOKEN': coo,
//     } 
//     return config;
// });

function combineUrl(url, id=null) {
    let finalUrl = `${SERVER_URL}${url}/`
    if (id) {
        finalUrl += `${id}/`
    }
    return finalUrl
}

// store.addNotification({
//     title: "Request success!",
//     message: "Add command.",
//     type: "success",
//     insert: "top",
//     container: "top-left",
//     animationIn: ["animate__animated", "animate__fadeIn"],
//     animationOut: ["animate__animated", "animate__fadeOut"],
//     dismiss: {
//       duration: 1500,
//       onScreen: true
//     }
//   })

export const getRequestProcess = ({dispatch}) => next => action => {
    next(action)

    if (ALL_REQUESTS.includes(action.type)) {
        const {url, id, params, onSuccess, onError} = action.payload
        let finalUrl = combineUrl(url)
        let method = ''
        switch(action.type) {
            case API_GET_REQUEST:
                method = 'GET'
                break
            case API_POST_REQUEST:
                method = 'post'
                break
            case API_PUT_REQUEST:
                method = 'PUT'
                finalUrl = combineUrl(url, id)
                break
            case API_DELETE_REQUEST:
                method = 'DELETE'
                finalUrl = combineUrl(url, id)
                break
        }

        const requestStartTime = Date.now()
        // const headers = { 'Content-Type': 'application/x-www-form-urlencoded' };
        axios({
            url: finalUrl,
            method,
            // headers,
            data: params,
        })
        .then((response) => dispatch({ type: onSuccess, payload: response.data }))
        .catch(error => {
            if (onError) {
                if (onError == API_REQUEST_FAILED_ASK_USER_FOR_RETRY) {
                    const retry = window.confirm('Action failed, do you want to retry? Check internet connection.')
                    
                    if (retry === true) {
                        dispatch(action)
                    }
                } else {
                    dispatch({ type: onError, payload: error })
                }
            }
            dispatch(reportServerError(error, finalUrl))
        })
        .then(() => dispatch(saveRequestDuration(requestStartTime, Date.now(), finalUrl)))
    }
    
    // if(action.type === API_GET_REQUEST) {
    //     const {url, onSuccess, onError} = action.payload
    //     axios.get(combineUrl(url))
    //     .then((response) => dispatch({ type: onSuccess, payload: response.data }))
    //     .catch(error => dispatch({ type: onError, payload: error }))
    //     .then()

    // } else if(action.type === API_POST_REQUEST) {
    //     const {url, params, onSuccess, onError} = action.payload
    //     axios.post(combineUrl(url), params)
    //     .then((response) => dispatch({ type: onSuccess, payload: response.data }))
    //     .catch(error => dispatch({ type: onError, payload: error }))

    // } else if(action.type === API_PUT_REQUEST) {
    //     const {url, id, params, onSuccess, onError} = action.payload
    //     axios.put(combineUrl(url, id), params)
    //     .then((response) => dispatch({ type: onSuccess, payload: response.data }))
    //     .catch(error => dispatch({ type: onError, payload: error }))

    // } else if(action.type === API_DELETE_REQUEST) {
    //     const {url, id, onSuccess, onError} = action.payload
    //     axios.delete(combineUrl(url, id))
    //     .then((response) => dispatch({ type: onSuccess, payload: response.data }))
    //     .catch(error => dispatch({ type: onError, payload: error }))
    // }
}

export const apiMiddleware = [getRequestProcess]