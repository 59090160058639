import {combineReducers} from 'redux'

import {tripsReducer} from './tripsReducer'
import {apiReducer} from './apiReducer'
import {areasReducer} from './areasReducer'
import {devicesReducer} from './devicesReducer'
import {errorsReducer} from './errorReducer'
import {gpsPositionsReducer} from './gpsPositionsReducer'
import {uiReducer} from './uiReducer'
import {serverUpdatesReducer} from './serverUpdatesReducer'
import {gpsArchiveReducer} from './gpsArchiveReducer'
import {mapReducer} from './mapReducer'
import {userReducer} from './userReducer'
import {reportsArchiveReducer} from './reportsArchiveReducer'

export const reducers = combineReducers({
    trips: tripsReducer,
    apiStats: apiReducer,
    areas: areasReducer,
    devices: devicesReducer,
    errors: errorsReducer,
    gps: gpsPositionsReducer,
    ui: uiReducer,
    serverUpdates: serverUpdatesReducer,
    gpsArchive: gpsArchiveReducer,
    reportsArchive: reportsArchiveReducer,
    map: mapReducer,
    user: userReducer,
})