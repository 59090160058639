import React, { useState, useEffect } from 'react'
import {Link, Switch, Route, useRouteMatch, withRouter} from 'react-router-dom'
import {useSelector, useDispatch} from 'react-redux'
import Calendar from 'react-calendar'

import Trips from './MainComponents/Trips'
import {Devices} from './MainComponents/Devices'
import {UserPanel} from './MainComponents/UserPanel'
import MapComponent from './Map/MapPage'
import {
  getGpsPositions
} from './redux/actions/gpsPositionsActions'
import {getTrips as fetchTrips} from './redux/actions/tripsActions'
import {getDevices as fetchDevices} from './redux/actions/devicesActions'
import {getAreas as fetchAreas} from './redux/actions/areasActions'
import {FlexBar} from './SmallComponents/flexBar'
import {TestingButtons} from './IconComponents/Buttons'

function TestTrips() {
  return(  
    <div>
      <h2>Trips</h2>
      <br/>
      <Trips />
    </div>
  )
}

function TestDevices() {
  return(  
    <div>
      <h2>Devices:</h2>
      <br/>
      <Devices />
    </div>
  )
}

function TestAreas() {
  return(
    <>
      <h2>Areas:</h2>
      <br/>
      {/* <Area /> */}
    </>
  )
}

function TestUserPanel() {
  return(
    <UserPanel />
  )
}

function TestMap() {
  return(
    <>
     <button>Add point</button>
     <MapComponent />
    </> 
  )
}

function TestButtons() {
  return <TestingButtons />
}


export default function Testing(props) {
  const dispatch = useDispatch()
  const {url} = useRouteMatch()
  const [padding, setPadding] = useState(10)

  const style = {
    background: 'white',
    height: '100vh',
    dislay: 'flex',
    flexDirection: 'column',
  }
  const navStyle = {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
  }
  const linkStyle = {
    color: 'black',
    border: '1px solid gray',
    borderRadius: '5px',
    padding: '6px',
    margin: '6px',
    textAlign: 'center',
  }
  const containerStyle = {
    padding: '0 ' + padding + 'px',
    height: '60%',
  }

  function changePadding(event) {
    const {value} = event.target
    setPadding(value)
  }

  function fetchAll() {
    dispatch(fetchTrips())
    dispatch(fetchDevices())
    dispatch(getGpsPositions())
  }

  function insertNumberIntoCalendarDay({ date, view }) {
    if (view === 'month') {
      return date.getDay() === 1 && <p style={{margin: '0'}}>(225)</p>
    }
    return null
  }

  return(
    <div style={style}>
      <div>
        <h3>Testing</h3>
        <div style={navStyle}>
          <Link style={linkStyle} to={`${url}/userpanel`}>User Panel</Link>
          <Link style={linkStyle} to={`${url}/trips`}>Trips </Link>
          <Link style={linkStyle} to={`${url}/devices`}>Devices</Link>
        </div>
        <div style={navStyle}>
          <Link style={linkStyle} to={`${url}/areas`}>Areas</Link>
          <Link style={linkStyle} to={`${url}/map`}>Map</Link>
          <Link style={linkStyle} to={`${url}/buttons`}>Buttons</Link>
        </div>
        <br/>
        <input
          onChange={changePadding}
          style={{margin: '0 auto', width: '100%'}}
          type='range' min='0' max='900'
        />
        <FlexBar>
          <button onClick={() => dispatch(fetchTrips())}>Fetch trips.</button>
          <button onClick={() => dispatch(fetchDevices())}>Fetch devices.</button>
        </FlexBar>
        <FlexBar>
          <button onClick={() => dispatch(fetchAreas())}>Fetch areas.</button>
          <button onClick={fetchAll}>Fetch all.</button>
        </FlexBar>
        <hr/>
      </div>
      <div style={{fontSize: '0.5em'}}>
        <Calendar
          tileContent={insertNumberIntoCalendarDay}
          onClickDay={(value, event) => alert('Clicked day: ', value)}
        />
      </div>

      <div style={containerStyle}>
        <Switch>
          <Route path={`${url}/trips`}>
            <TestTrips />
          </Route>
          <Route path={`${url}/devices`}>
            <TestDevices />
          </Route>
          <Route path={`${url}/userpanel`}>
            <TestUserPanel />
          </Route>
          <Route path={`${url}/areas`}>
            <TestAreas />
          </Route>
          <Route path={`${url}/map`}>
            <TestMap />
          </Route>
          <Route path={`${url}/buttons`}>
            <TestButtons />
          </Route>
        </Switch>
      </div>
    </div>
  )
}