import React, {useState, useEffect, useRef} from 'react'
import {useSelector, useDispatch} from 'react-redux'
import moment from 'moment'

// import "react-tabulator/css/bootstrap/tabulator_bootstrap4.min.css"; // use Theme(s)
import {usePinning} from '../customHooks/usePinning'
import {Toolbar, InfoBar} from '../IconComponents/IconBar'
import {FlexBar, FlexCol} from '../SmallComponents/flexBar'
import * as Buttons from '../IconComponents/Buttons'
import {
  IndicatorBattery,
  IndicatorSatellites,
  IndicatorConnection,
  IndicatorLastTransmission,
  IndicatorSignal,
  IndicatorTemperature,
  IndicatorRamUsage,
} from '../IconComponents/Indicators'
import {GpsCalendar} from './GpsCalendar'
import {
  DeviceStatistics,
  CMD_POWER_OFF_DEVICE,
  CMD_RESTART_DEVICE,
  CMD_UPDATE_REPO,
  getErrorsFromAllSections,
  BatteryIndicatior,
} from './DeviceStatistics'

// REDUX ACTIONS
import {reportAppError} from '../redux/actions/errorActions'
import {scrollToDeviceById, showDeviceDetails} from '../redux/actions/uiActions'
import {setCenteringResponsibleObject, centerMapHere} from '../redux/actions/mapActions'
import {setDevicesFiltering, setGpsFiltering} from '../redux/actions/serverUpdatesActions'
import {
  createCommand,
  filterInactiveDevices,
  toggleShowGpsTrails,
  hideDevice,
  hideAllDevices,
} from '../redux/actions/devicesActions'
// REDUX REDUCERS
import {selectMapStore} from '../redux/reducers/mapReducer'
import {
  selectDeviceById,
  selectCommandsById,
  selectDevicesArray,
  selectFilteredDevicesArray,
  selectDevicesWithErrors,
  selectFilterInactiveDevices,
  selectHiddenDevices,
  selectShowGpsTrails,
} from '../redux/reducers/devicesReducer'
import {selectUiStore} from '../redux/reducers/uiReducer'
import {selectServerUpdatesStore} from '../redux/reducers/serverUpdatesReducer'


export function Device({data, Pin, scrollTo, noButtons=false, isSelected=false, selectionCallback, isTracked=false}) {
  // const [loadingStatus, updateLoadingStatus] = useState('')
  // const [hidden, setHidden] = useState(false)
  const dispatch = useDispatch()
  const refForFocus = useRef(null)
  const [hideDetails, setHideDetails] = useState(true)
  const [repoUpdateDisabled, setRepoUpdateDisabled] = useState(false)
  const filterInactiveDevicesData = useSelector(selectFilterInactiveDevices)
  const hiddenDevices = useSelector(selectHiddenDevices)
  const [showGpsCalendar, setShowGpsCalendar] = useState(false)

  const deviceReport = data.last_report
  const errorCount = data.error_count

  let report = '-'
  let timestampSent = '-'
  if (deviceReport) {
    ({timestamp_sent: timestampSent,
      report,} = deviceReport)
  }

  let errorsPresent = false
  if (report !== '-') {
    try {
      const allErrors = getErrorsFromAllSections(report)
      errorsPresent = (allErrors.length > 0)
    } catch (error) {
      dispatch(reportAppError(error, 'Parsing device statistics in Device component (most likely json error).'))
    }
  }
  const batteryPercent = getDataFromModel(report, 'battery', 'remaining_percent')
  const batteryTime = getDataFromModel(report, 'battery', 'remaining_time')
  const signalQuality = getDataFromModel(report, 'modem', 'modem_signal')
  const temperature = getDataFromModel(report, 'device_statistics', 'cpu_temp')
  const softwareVersion = getDataFromModel(report, 'device_statistics', 'git_commit')
  const gpsSignalQuality = getDataFromModel(report, 'gps', 'signal_quality', 0)
  const gpsHasFix = (gpsSignalQuality > 0)
  let visibleSatellites = getDataFromModel(report, 'gps', 'visible_satellites')
  if (visibleSatellites == '-1') {
    visibleSatellites = '-'
  }
  const satellitesInView = getDataFromModel(report, 'gps', 'satellites_in_view')
  const operationMode = getDataFromModel(report, 'modem', 'operation_mode')

  const timestamp = moment(timestampSent)
  const ageOfReport = moment().diff(timestamp, 'seconds')

  useEffect(() => {
    if (scrollTo) {
      refForFocus.current.scrollIntoView({ behavior: 'smooth', block: 'start' })
    }
  }, [scrollTo])

  const toggleHideDevice = (state) => {
    dispatch(hideDevice(data.id, !state))
  }

  function toggleTracking() {
    if (isTracked) {
      dispatch(setCenteringResponsibleObject('device', data.id, false))
    } else {
      dispatch(setCenteringResponsibleObject('device', data.id, true))
    }
  }

  function toggleDeviceSelection() {
    // dispatch(scrollToDeviceById(data.id))
    if (isSelected) {
      dispatch(setCenteringResponsibleObject(null, null, false))
      dispatch(scrollToDeviceById(null))
    } else {
      dispatch(setCenteringResponsibleObject('device', data.id, false))
    }
    selectionCallback(data.id)
  }

  const toolbar = (
    <>
      <Buttons.Hide
        togglerMode={true}
        setActive={!hiddenDevices.includes(data.id)}
        action={toggleHideDevice}
      />
      <Buttons.Tracking togglerMode={true} setActive={isTracked} action={toggleTracking} />
      {errorCount > 0
        &&  <Buttons.Errors
              rightText={errorCount || null}
              numOfErrors={errorCount}
              action={() => dispatch(showDeviceDetails(data.id))}
            />
      }
      <Buttons.Expand action={() => setHideDetails(p => !p)} superStyle={errorsPresent ? {color: 'red'} : null}/>
      {Pin}
    </>
  )

  const advancedToolbar = (
    <>
      <Buttons.Statistics action={() => dispatch(showDeviceDetails(data.id))} />
      <Buttons.Calendar action={() => setShowGpsCalendar(p => !p)} />
      <Buttons.UpdateRepo action={sendCommandUpdateRepo} disabled={repoUpdateDisabled}/>
    </>
  )

  const advancedToolbar2 = (
    <>
      <Buttons.Restart action={sendCommandRestart} />
      <Buttons.PowerOff action={sendCommandPowerOff} />
    </>
  )

  function getDataFromModel(data, modelName, fieldName, defaultValue='-') {
    try {
      const val = data[modelName]['data'][fieldName][0]
      if (val == null || val.length === 0) {
        return defaultValue
      }
      if (typeof val === 'string' && val.startsWith('#')) {
        return '#ERR'
      }
      return val
    } catch (error) {
      return '#ERR*'
    }
  }

  function sendCommandPowerOff() {
    let confirmed = window.confirm('Confirm device power OFF.')
    if (confirmed) {
      dispatch(createCommand(data.id, CMD_POWER_OFF_DEVICE, 'token'))
    }
  }

  function sendCommandRestart() {
    let confirmed = window.confirm('Confirm device restart.')
    if (confirmed) {
      dispatch(createCommand(data.id, CMD_RESTART_DEVICE, 'token'))
    }
  }

  function sendCommandUpdateRepo() {
    setRepoUpdateDisabled(true)
    dispatch(createCommand(data.id, CMD_UPDATE_REPO, 'token'))
    setTimeout(() => setRepoUpdateDisabled(false), 12000)
  }

  let classNames = 'device-element'
  if (isSelected) {
      classNames += ' show-focus-device'
  }

  let freshnessBackground = 'white'
  if (ageOfReport > 800) {
    freshnessBackground = '#d4d4d4'
  } else if (ageOfReport > 120) {
    freshnessBackground = '#e8e8e8'
  }

  const NameIdComp = noButtons ? (
    <FlexBar style={{marginTop: '7px'}} onClick={toggleDeviceSelection}>
      <h4>Name: {data.name} </h4>
      <h5>ID: {data.serial}</h5>
    </FlexBar>
  ) : (
    <FlexBar style={{borderBottom:'1px solid #d6d6d6'}}>
      <FlexCol style={{alignItems: 'flex-start'}} onClick={toggleDeviceSelection}>
        <h4>Name: {data.name} </h4>
        <h5>ID: {data.serial}</h5>
      </FlexCol>
      <Toolbar className=''>
        {toolbar}
      </Toolbar>
    </FlexBar>
  )

  return (
    <div
      className={classNames}
      ref={refForFocus}
      style={{background: freshnessBackground}}
    >
      <BatteryIndicatior batteryPercent={batteryPercent} batteryTime={batteryTime}/>
      {NameIdComp}
      <div>
        {/* <div className={selected} onClick={toggleDeviceSelection}> */}
        <FlexBar > 
          <FlexBar>
            <i style={{marginRight: '5px'}} className="fas fa-signal"></i>
            {signalQuality}
            <div style={{margin: '0 5px'}}>
              <IndicatorSatellites hasFix={gpsHasFix} />
            </div>
            {`${visibleSatellites}/${satellitesInView}`}
            <i style={{margin: '0 5px'}} className="fas fa-tenge"></i>
            {operationMode}
          </FlexBar>
          {softwareVersion}
          <InfoBar>
            <IndicatorTemperature val={temperature} />
          </InfoBar>
          <FlexBar>
            {ageOfReport}<i style={{margin: '0 5px', color: ageOfReport < 60 ? 'green' : 'red'}} className="far fa-clock"></i>
            <IndicatorLastTransmission val={timestamp.format("HH:mm:ss DD.MM.YYYY")} />
          </FlexBar>
        </FlexBar>
        {isSelected && !noButtons
          ? 
            <>
              <hr style={{marginBottom: '-0.5em'}} />
              <FlexBar>
                <Toolbar className=''>
                  {advancedToolbar}
                </Toolbar>
                <Toolbar className=''>
                  {advancedToolbar2}
                </Toolbar>
              </FlexBar>
            </>
          : null
        }
        <GpsCalendar data={data} show={showGpsCalendar && isSelected}/>
        {hideDetails ? null : <DeviceStatistics deviceStatsObj={report}/>}
      </div>
    </div>
  )
}

export function DevicesBatch({devices, multiSelect=false, selectedDevices=[], setSelectedDevices, noButtons=false}) {
  const {scrollTo} = useSelector(selectUiStore)
  const [sortByPins, getPin] = usePinning()
  const devicesSorted = sortByPins(devices)
  const {name: centeringObjName, id: centeringObjId, tracking} = useSelector(selectMapStore).centeringResponsibleObject

  let deviceToScrollTo = null
  if (!multiSelect && scrollTo['device']) {
      deviceToScrollTo = scrollTo['device']
  }

  function selectionCallback(id) {
    if (multiSelect) {
      if (selectedDevices.includes(id)) {
        setSelectedDevices(p => {
          const newList = p.filter(item => item !== id)
          return newList
        })
      } else {
        setSelectedDevices(p => {
          const newList = [...p, id]
          return newList
        })
      }
    }
  
    // else {
    //   if (selectedDevices.includes(id)) {
    //     setSelectedDevices([])
    //   } else {
    //     setSelectedDevices([id])
    //   }
    // }
  }

  function toggleSelectAll(state) {
    if (state) {
      const newList = devices.map(device => device.id)
      setSelectedDevices(newList)
    } else {
      setSelectedDevices([])
    }
  }

  function checkIfSelected(id) {
    if (multiSelect) {
      return selectedDevices.includes(id)
    } else {
      return (centeringObjName === 'device' && centeringObjId === id) || deviceToScrollTo === id
    }
  }

  function checkIfTracker(id) {
    return centeringObjName === 'device' && centeringObjId === id && tracking
  }

  const devicesComponents = devicesSorted.map(device => {
    return <Device
                key={device.id}
                data={device}
                Pin={getPin(device.id)}
                noButtons={noButtons}
                scrollTo={deviceToScrollTo === device.id}
                isSelected={checkIfSelected(device.id)}
                isTracked={checkIfTracker(device.id)}
                selectionCallback={selectionCallback}
            />
  })

  return (
    <>
      {multiSelect
        ? <>
            <FlexBar>
                <span style={{fontSize: '1.25em'}}>Selected devices: {selectedDevices.length}</span>
                <Toolbar>
                    <label>
                        <span style={{fontSize: '0.85em'}}>Select all: </span><Buttons.Check action={toggleSelectAll} />
                    </label>
                </Toolbar>
            </FlexBar>
            {devices && devices.length > 0 ? null : <div style={{color: 'red'}}>Toggle devices filtering if no devices show up</div>}
          </>
        : null
      }
      {devicesComponents}
    </>
  )
}

const gpsPointsOptions = [
  10, 30, 50, 100, 200, 500
]

export function Devices() {
  const devices = useSelector(selectFilteredDevicesArray)
  const devicesWithErrors = useSelector(selectDevicesWithErrors)
  const numOfAllDevices = useSelector(selectDevicesArray).length
  const filterInactiveDevicesData = useSelector(selectFilterInactiveDevices)
  const shouldShowGpsTrails = useSelector(selectShowGpsTrails)
  const {gpsFilteringValue} = useSelector(selectServerUpdatesStore)
  const numOfDevices = devices.length
  const dispatch = useDispatch()

  function filterByActivity() {
    dispatch(filterInactiveDevices())
  }

  function setNextNumOfPoints() {
    const currentIndex = gpsPointsOptions.indexOf(gpsFilteringValue)
    const nextIndex = (currentIndex + 1) % (gpsPointsOptions.length)
    dispatch(setGpsFiltering(gpsPointsOptions[nextIndex]))
  }

  return (
    <div className='big-toolbar-container'>
      <div className='device-title'>
        <FlexBar>
          <h2>Devices: {numOfDevices}/{numOfAllDevices}</h2>
          <Toolbar>
            {devicesWithErrors > 0 && <Buttons.Errors rightText={devicesWithErrors || null} numOfErrors={devicesWithErrors} action={() => {}} />}
            <Buttons.FilterDevices />
            <Buttons.Hide action={(state) => dispatch(hideAllDevices(!state))} />
            <Buttons.Trips
              action={() => dispatch(toggleShowGpsTrails())}
              togglerMode={true}
              setActive={shouldShowGpsTrails}
            />
            <div style={{marginRight:'10px'}}></div>
            <Buttons.NumOfPoints
              action={setNextNumOfPoints}
              togglerMode={true}
              setActive={false}
              rightText={gpsFilteringValue}
            />
          </Toolbar>
          </FlexBar>
      </div>
      <div className='scrollable-big-toolbar'>
          <DevicesBatch devices={devices} />
      </div>
    </div>
  )
}
