import React, {useState, useEffect, useRef} from 'react'
import {useSelector, useDispatch} from 'react-redux'

import {FlexCol, FlexBar} from '../SmallComponents/flexBar'
import * as Buttons from '../IconComponents/Buttons'


// REDUX ACTIONS
import {
    fetchDevicesErrors,
    fetchDevicesErrorTypes,
    SET_UPDATE_IN_PROGRESS,
    archiveDevicesErrors,
} from '../redux/actions/errorActions'
// REDUCERS
import {selectDeviceErrorsById, selectErrorStore} from '../redux/reducers/errorReducer'


export function ErrorInstance({data}) {
    const {type, type_hash, device, date, attachments} = data
    return (
        <div style={{border: '1px solid gray', padding: '3px 7px', margin: '3px 0'}}>
            <div>{date} [T: {type} D: {device}]</div>
        </div>
    )
}

export function ErrorType({data, errorInstances, deviceId}) {
    const dispatch = useDispatch()
    const {id, type, message, trace, file, line, hash} = data
    const formattedTrace = trace.split('\n')

    if (!errorInstances || errorInstances.length === 0) {
        return null
    }

    return (
        <FlexBar style={{border: '1px solid gray', alignItems: 'flex-start', padding: '5px', margin: '5px 0'}}>
            <div>
                {errorInstances.map((instanceData, idx) => {
                    return <ErrorInstance key={idx} data={instanceData} />
                })}
            </div>
            <div style={{flexGrow: 1, margin: '0 12px'}}>
                <div><b>Instances:</b> {errorInstances.length}</div>
                <div><b>Type:</b> {type}</div>
                <div><b>Title:</b> {message}</div>
                <div><b>File:</b> {file} <b>[Line: {line}]</b></div>
                <div><b>Hash:</b> {hash}</div>
                <div><b>Trace:</b> {formattedTrace.map(line => <div>{line}</div>)}</div>
            </div>
            <button onClick={() => dispatch(archiveDevicesErrors(deviceId, id))}>Archive instances of this type</button>
        </FlexBar>
    )
}

export function DeviceErrorsArchive({deviceId}) {
    const dispatch = useDispatch()
    const errorInstances = useSelector(selectDeviceErrorsById(deviceId))
    const {updateInProgress, devicesErrors} = useSelector(selectErrorStore)
    const {errorTypes} = devicesErrors

    function updateErrorTypesFromServer() {
        dispatch({type: SET_UPDATE_IN_PROGRESS})
        dispatch(fetchDevicesErrorTypes())
    }

    function updateDeviceErrorsFromServer() {
        dispatch({type: SET_UPDATE_IN_PROGRESS})
        dispatch(fetchDevicesErrors(deviceId))
    }

    useEffect(() => {
        if (deviceId !== '-' && ! updateInProgress && ! errorInstances) {
            updateDeviceErrorsFromServer()
        }
    }, [deviceId, errorInstances])

    let errorTypesComps = null
    if (errorTypes) {
        errorTypesComps = Object.values(errorTypes).map((data, idx) => {
            const typeId = data.id
            let instances = []
            if (errorInstances) {
                instances = errorInstances.filter(data => data.type === typeId)
            }
            return <ErrorType key={idx} data={data} errorInstances={instances} deviceId={deviceId} />
        })
    }

    function archiveAllErrorsForDevice() {
        const confirm = window.confirm('Confirm archiving all errors for this device.')
        if (confirm) {
            dispatch(archiveDevicesErrors(deviceId))
        }
    }

    return (
        <div style={{background: 'white', margin: '5px, 12px'}}>
            <FlexBar>
                <FlexBar style={{flexGrow: '1', justifyContent: 'center'}}>
                    <h2>Device errors [{errorInstances ? errorInstances.length : '-'}]</h2>
                    <Buttons.Refresh action={updateDeviceErrorsFromServer} rotate={updateInProgress} disabled={updateInProgress} />
                </FlexBar>
                <FlexBar style={{flexGrow: '1', justifyContent: 'center'}}>
                    <h2>Error types</h2>
                    <Buttons.Refresh action={updateErrorTypesFromServer} rotate={updateInProgress} disabled={updateInProgress} />
                </FlexBar>
                <button onClick={archiveAllErrorsForDevice}>Archive all for this device</button>
            </FlexBar>
            {errorTypesComps}
        </div>
    )
}