import React, {Component, useState, useEffect} from 'react'
import {useSelector, useDispatch} from 'react-redux'

import './style.css'
import { CircularProgressbar } from 'react-circular-progressbar';
import {GridToolbar} from '../IconComponents/IconBar'
import BaseButton from './BaseButton'

import {
  filterInactiveDevices,
} from '../redux/actions/devicesActions'

import {
  selectFilterInactiveDevices,
} from '../redux/reducers/devicesReducer'

// TODO more robust styles for the future
// function StyleNavi(direction) {
//     return {
//         moveDirection: direction,
//     }
// }

// function StyleCircular(size) {
//     return {
//         circleSize: size,
//     }
// }

// export function circularStyle(size, ) {
//     return 
// }

const COLOR_OK = '#1dbf08'
const COLOR_ORANGE = 'rgb(231, 155, 14)'



export class Expand extends BaseButton {
    constructor(props) {
        super(props, {
            icon: 'far fa-caret-square-down',
            iconActive:'far fa-caret-square-up',
            divStyle: 'centered-row',
        })
    }

    performAction = () => {
        if (this.props.val === 0) {
            this.props.action(false)
        } else {
            this.props.action(p => !p)
        }
    }

    updateForce(prevProps) {
        if (this.props.force !== prevProps.force) {
            this.props.action(this.props.force)
        }   
    }

    componentDidUpdate(prevProps) {
        this.updateForce(prevProps)
    }
}

export class Pin extends BaseButton {
    constructor(props) {
        super(props, {
            // icon: "fas fa-thumbtack",
            icon: 'far fa-star',
            iconActive: 'fas fa-star',
            color: COLOR_ORANGE,
        })
    }
    // Add style ! ! ! ! ! ! ! ! ! ! ! ! !
    performAction= (isActiveNow) => {
        if (isActiveNow) {
            this.state.addPin(this.state.id)
        } else {
            this.state.removePin(this.state.id)
        }
    }
}

export class UpdateRepo extends BaseButton {
    constructor(props) {
        super(props, {
            icon: 'fas fa-download',
        })
    }
}

export class Check extends BaseButton {
    constructor(props) {
        super(props, {
            icon: 'far fa-square',
            iconActive: 'far fa-check-square',
        })
    }
}

export class SettingsUser extends BaseButton {
    constructor(props) {
        super(props, {
            icon: 'fas fa-user-cog',
        })
    }
}

export class Restart extends BaseButton {
    constructor(props) {
        super(props, {
            icon: 'fas fa-redo-alt',
        })
    }
}

export class PowerOff extends BaseButton {
    constructor(props) {
        super(props, {
            icon: 'fas fa-power-off',
        })
    }
}

export class Exit extends BaseButton {
    constructor(props) {
        super(props, {
            icon: 'far fa-times-circle',
        })
    }
}

export class Statistics extends BaseButton {
    constructor(props) {
        super(props, {
            icon: 'fas fa-chart-bar',
        })
    }
}

export class Create extends BaseButton {
    constructor(props) {
        super(props, {
            icon: 'fas fa-plus',
        })
    }
}

export class Lock extends BaseButton{
    constructor(props) {
        super(props, {
            icon: `fas fa-lock`,
            iconActive: `fas fa-unlock`,
            color: COLOR_ORANGE,
        })
    }
}

export class Rename extends BaseButton{
    constructor(props) {
         super(props, {
            icon: "fas fa-pencil-alt",
        })
    }
}

export class MoveDevicesToTrip extends BaseButton{
    constructor(props) {
        super(props, {
            icon: "fas fa-external-link-alt",
        })
    }
}

export class Delete extends BaseButton{
    constructor(props) {
        super(props, {
            icon: "far fa-trash-alt",
        })
    }
}

export class Tracking extends BaseButton{
    constructor(props) {
        super(props, {
            icon: "fas fa-bullseye",
            iconActive: 'far fa-dot-circle',
            color: COLOR_ORANGE,
        })
    }
}

export class Hide extends BaseButton {
    constructor(props) {
        super(props, {
            icon: 'fas fa-eye-slash',
            iconActive: 'fas fa-eye',
            color: COLOR_ORANGE,
        })
    }
}

export class HideLabels extends BaseButton {
    constructor(props) {
        super(props, {
            icon: 'fas fa-tags',
            color: COLOR_ORANGE,
        })
    }
}

export function FilterDevices() {
    const dispatch = useDispatch()
    const filterInactiveDevicesData = useSelector(selectFilterInactiveDevices)

    function filterByActivity() {
        dispatch(filterInactiveDevices())
      }

    return (
      <Filter
        action={filterByActivity}
        togglerMode={true}
        setActive={!! filterInactiveDevicesData}
        rightText={filterInactiveDevicesData ? filterInactiveDevicesData : '-'}
      />
    )

}

export class Filter extends BaseButton {
    constructor(props) {
        super(props, {
            icon: 'fas fa-filter',
            color: COLOR_ORANGE,
        })
    }
}

export class NumOfPoints extends BaseButton {
    constructor(props) {
        super(props, {
            icon: 'fas fa-crow',
            color: COLOR_ORANGE,
        })
    }
}

export class ShowOnMap extends BaseButton {
    constructor(props) {
        super(props, {
            icon: "fas fa-map-marked-alt",
        })
    }
}

export class Calendar extends BaseButton {
    constructor(props) {
        super(props, {
            icon: "far fa-calendar-alt",
            color: COLOR_ORANGE,
        })
    }
}

export class Refresh extends BaseButton {
    constructor(props) {
        super(props, {
            icon: "fas fa-sync-alt"
        })
    }
}

export class Logout extends BaseButton {
    constructor(props) {
        super(props, {
            icon: "fas fa-sign-out-alt",
        })
    }
}


// odl version of basic Play-pause button
// export const ButtonPlayPause = makeButton((action, state) => {
//     const [pause, setPause] = useState(true)
    
//     useEffect(() => {
//         action(!pause)
//     }, [pause])

//     return(
//         <Button onClick={() => setPause(p => !p)}>
//             <i
//                 style={{color: pause ? 'red' : 'green'}}
//                 className={`far fa-${pause ? 'pause' : 'play'}-circle`}
//             >
//             </i>
//         </Button>
//     )
// })

export class PlayPauseProgress extends BaseButton { 
    constructor(props) {
        super(props)
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevProps.timer !== this.props.timer) {
            this.setState({timer: this.props.timer})
        }
    }

    render() {
        return super.render(
            <GridToolbar>
                <CircularProgressbar
                    className='grid-center'
                    value={this.state.timer} 
                    // text={`${timerLimit - timer}/${timerLimit}`}
                    text={null}
                    maxValue={this.state.timerLimit}
                    strokeWidth='18'  
                    styles={{
                    root: {
                        width: '26px',
                        height: '26px',
                    },
                    text: {
                        fontSize: '24px',
                    },
                    path: {
                        transition: 'none',
                        stroke: 'green',
                    }
                            // timer === 0 ? "none" : "stroke-dashoffset 0.5s ease 0s",
                    }}
                />
                <i
                    style={{color: this.state.isActive ? 'green' : 'red'}}
                    className={`grid-center far fa-${this.state.isActive ? 'play' : 'pause'}-circle`}
                >
                </i>
            </GridToolbar>
        )
    }
}

export class SlowDown extends BaseButton {
    constructor(props) {
        super(props, {
            icon: 'fas fa-backward',
        })
    }
}

export class SpeedUp extends BaseButton {
    constructor(props) {
        super(props, {
            icon: 'fas fa-forward',
        })
    }
}

export class Map extends BaseButton {
    constructor(props) {
        super(props, {
            icon: 'fa fa-map-marked-alt',
        })
    }
}

export class Lab extends BaseButton {
    constructor(props) {
        super(props, {
            icon: 'fas fa-flask',
        })
    }
}

export class Device extends BaseButton {
    constructor(props) {
        super(props, {
            icon: 'fas fa-satellite-dish',
        })
    }
}

export class Trips extends BaseButton {
    constructor(props) {
        super(props, {
            icon: 'fas fa-route',
            color: COLOR_ORANGE,
        })
    }
}

export class Areas extends BaseButton {
    constructor(props) {
        super(props, {
            icon: 'far fa-file-audio',
        })
    }
}

export class FullScreen extends BaseButton {
    constructor(props) {
        super(props, {
            icon: 'fas fa-expand',
            iconActive: 'fas fa-compress',
        })
    }
}

export class User extends BaseButton {
    constructor(props) {
        super(props, {
            icon: 'fas fa-user-circle',
        })
    }
}

export class OkNok extends BaseButton {
    constructor(props) {
        super(props, {
            icon: 'far fa-check-circle',
            iconActive: 'far fa-times-circle',
        })
    }
}

export class Menu extends BaseButton {
    constructor(props) { 
        super(props, {
            icon: 'fas fa-bars',
            independentRender: true,
        })
    }

    performAction = (isActiveNow) => {
        // this is for overwriting the base method which causes an error
        // as it uses a prop not delivered to this component
    }

    renderChildren = () => {
        if (!this.state.isActive) { return null }

        const distanceFromCenter = 98
        const children = this.props.children
        const numOfChildren = children.length

        let childrenComponents = []
        for (let i=0; i<numOfChildren; i++) {
            const angleDiff = Math.PI * 1.06 / (numOfChildren - 1)
            const currentAngle = Math.PI * 1.03 - (angleDiff * i)
            const style = {
                position: 'relative',
                left: distanceFromCenter * Math.cos(currentAngle) + 'px',
                top:  (-distanceFromCenter * Math.sin(currentAngle)) - 83 + 'px',
            }
            childrenComponents.push(
                <div key={i} style={style}>{children[i]}</div>
            )
        }
        return childrenComponents
    }
}

export class ArrowLeft extends BaseButton {
    constructor(props) {
        super(props, {
            icon: 'far fa-arrow-alt-circle-left',
        })
    }
}

export class ArrowRight extends BaseButton {
    constructor(props) {
        super(props, {
            icon: 'far fa-arrow-alt-circle-right'
        })
    }
}

export class Increase extends BaseButton {
    constructor(props) {
        super(props, {
            icon: 'fas fa-plus-circle'
        })
    }
}

export class Decrease extends BaseButton {
    constructor(props) {
        super(props, {
            icon: 'fas fa-minus-circle'
        })
    }
}

export class MoveOrToolSelection extends BaseButton {
    constructor(props) {
        super(props, {
            icon: 'fas fa-arrows-alt',
            iconActive: 'fas fa-mouse-pointer',
        })
    }
}

export class Save extends BaseButton {
    constructor(props) {
        super(props, {
            icon: 'far fa-save'
        })
    }
}

export class Reject extends BaseButton {
    constructor(props) {
        super(props, {
            icon: 'far fa-times-circle'
        })
    }
}

export class Errors extends BaseButton {
    constructor(props) {
        super(props, {
            icon: 'fas fa-bug',
        })
    }
    performAction = (isActiveNow) => {
        this.props.action(p => p == 'errors' ? false : 'errors')
    }

    updateColor() {
        if (this.props.numOfErrors || this.props.numOfErrors == 0) {
            if(this.props.numOfErrors > 0) {
                this.setState({style: 'default-button red-button', icon: 'fas fa-bug'})
            } else {
                this.setState({style: 'default-button green-button', icon: 'far fa-check-circle'})
            }
        }
    }

    componentDidMount() {
        this.updateColor()
    }

    componentDidUpdate(prevProps) {
        if (prevProps.numOfErrors != this.props.numOfErrors) {
            // console.log(prevProps.numOfErrors)
            // console.log(this.props.numOfErrors)
            this.updateColor()
        }
    }
}

export class Performance extends BaseButton {
    constructor(props) {
        super(props, {
            icon: 'fas fa-tachometer-alt'
        })
    }

    performAction = (isActiveNow) => {
        this.props.action(p => p == 'performance' ? false : 'performance')
    }
}

export function TestingButtons() {
    return (
        <div>
            Testing buttons
            <br/>
            <BaseButton />
            <br/>
            <Map action={() => console.log('Action!')}/>
            <br/>
            <Map action={(arg) => console.log(String(arg))}/>
        </div>
    )
}
