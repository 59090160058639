import logger from 'redux-logger'
import {applyMiddleware} from "redux"

import {apiMiddleware} from './apiMiddleware'
import {tripsMiddleware} from './tripsMiddleware'
import {areasMiddleware} from './areasMiddleware'
import {devicesMiddleware} from './devicesMiddleware'
import {gpsPositionsMiddleware} from './gpsPositionsMiddleware'
import {serverUpdatesMiddleware} from './serverUpdatesMiddleware'
import {gpsArchiveMiddleware} from './gpsArchiveMiddleware'
import {reportsArchiveMiddleware} from './reportsArchiveMiddleware'
import {userMiddleware} from './userMiddleware'
import {errorsMiddleware} from './errorsMiddleware'


export const middlewares = applyMiddleware(
    ...apiMiddleware,
    ...tripsMiddleware,
    ...areasMiddleware,
    ...devicesMiddleware,
    ...gpsPositionsMiddleware,
    ...serverUpdatesMiddleware,
    ...gpsArchiveMiddleware,
    ...reportsArchiveMiddleware,
    ...userMiddleware,
    ...errorsMiddleware,

    logger,
)