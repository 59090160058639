const PREFIX = '[map]'

export const SET_MAP_OBJECT = `${PREFIX} SET_MAP_OBJECT`
export const SET_SHOW_ARCHIVAL_GPS_POINTS = `${PREFIX} SET_SHOW_ARCHIVAL_GPS_POINTS`
export const SHOW_LIVE_DEVICES_AND_AREAS = `${PREFIX} SHOW_LIVE_DEVICES_AND_AREAS`

export const CENTER_MAP_HERE = `${PREFIX} SET_MAP_CENTER`
export const SET_CENTERING_RESPONSIBLE_OBJ = `${PREFIX} SET_CENTERING_RESPONSIBLE_OBJ`


export const setMapObject = (mapObject) => ({
    type: SET_MAP_OBJECT,
    payload: mapObject,
})

export const setShowArchivalGpsPoints = (device, day, points) => ({
    type: SET_SHOW_ARCHIVAL_GPS_POINTS,
    payload: {
        device,
        day,
        points,
    }
})

export const setCenteringResponsibleObject = (name, id, tracking) => ({
    type: SET_CENTERING_RESPONSIBLE_OBJ,
    payload: {
        name,
        id,
        tracking,
    }
})

export const centerMapHere = (location) => ({
    type: CENTER_MAP_HERE,
    payload: location,
})

export const showLiveDevicesAndAreas = () => ({
    type: SHOW_LIVE_DEVICES_AND_AREAS,
})