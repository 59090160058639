const PREFIX = '[error]'

// Command type actions (middleware only)
export const REPORT_APP_ERROR = `${PREFIX} REPORT_APP_ERROR`
export const REPORT_SERVER_ERROR = `${PREFIX} REPORT_SERVER_ERROR`
export const FETCH_DEVICES_ERRORS = `${PREFIX} FETCH_DEVICES_ERRORS`
export const FETCH_DEVICES_ERROR_TYPES = `${PREFIX} FETCH_DEVICES_ERROR_TYPES`
export const ARCHIVE_DEVICE_ERRORS = `${PREFIX} ARCHIVE_DEVICE_ERRORS`


// Document type actions (reducers only)
export const UPDATE_DEVICES_ERRORS = `${PREFIX} UPDATE_DEVICES_ERRORS`
export const REPORT_ERROR_TO_SERVER = `${PREFIX} REPORT_TO_SERVER`
export const SET_UPDATE_IN_PROGRESS = `${PREFIX} SET_UPDATE_IN_PROGRESS`

// Clearing errors
export const CLEAR_ALL_ERRORS = `${PREFIX} CLEAR_ALL`
export const CLEAR_APP_ERRORS = `${PREFIX} CLEAR_APP`

export const SERVER_COM_FAILED = `${PREFIX} SERVER_COM_FAILED`


// Action creators
export const reportAppError = (error, source) => ({
    type: REPORT_APP_ERROR,
    payload: {
        date: new Date(),
        error,
        source,
    }
})

export const reportServerError = (error, source) => ({
    type: REPORT_SERVER_ERROR,
    payload: {
        date: new Date(),
        error,
        source,
    }
})

export const clearAppErrors = () => ({
    type: CLEAR_APP_ERRORS,
})

export const clearAllErrors = () => ({
    type: CLEAR_ALL_ERRORS,
})

export const fetchDevicesErrors = (id) => ({
    type: FETCH_DEVICES_ERRORS,
    payload: id,
})

export const fetchDevicesErrorTypes = () => ({
    type: FETCH_DEVICES_ERROR_TYPES,
})

export const updateDevicesErrors = (errors) => ({
    type: UPDATE_DEVICES_ERRORS,
    payload: errors,
})

export const archiveDevicesErrors = (device, errorType=null) => ({
    type: ARCHIVE_DEVICE_ERRORS,
    payload: {
        device,
        errorType,
    },
})

// TODO implement
export const reportErrorToServer = (error) => ({
    type: REPORT_ERROR_TO_SERVER,
    payload: error,
})