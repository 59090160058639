import React from 'react'

export function FlexBar({children, background, className, style={}, onClick}) {
  return(
    <div
      className={'flex-bar ' + className + (onClick ? ' text-button' : null)}
      onClick={onClick}
      style={{
        background: background,
        ...style,
      }}
      >
      {children}
    </div>
  )
}

export function FlexCol({children, style, className, onClick}) {
  return(
    <div
      className={'flex-col ' + className + (onClick ? ' text-button' : null)}
      onClick={onClick} style={style}
    >
      {children}
    </div>
  )
}