import {
    getRequest,
    postRequest,
    deleteRequest,
    putRequest,
    API_REQUEST_FAILED_ASK_USER_FOR_RETRY
} from '../actions/apiActions'
import {
    FETCH_GENERAL_SETTINGS,
    UPDATE_GENERAL_SETTINGS,
    SEND_GENERAL_SETTINGS_TO_SERVER,
} from '../actions/userActions'

const GENERAL_SETTINGS_INFO_URL = 'settings'

const getSettingsProcess = ({dispatch}) => next => action => {
    next(action)
    if (action.type === FETCH_GENERAL_SETTINGS) {
        dispatch(getRequest(GENERAL_SETTINGS_INFO_URL, UPDATE_GENERAL_SETTINGS, API_REQUEST_FAILED_ASK_USER_FOR_RETRY))
    }
}

const modifySettingsProcess = ({dispatch}) => next => action => {
    next(action)
    if (action.type === SEND_GENERAL_SETTINGS_TO_SERVER) {
        const params = action.payload
        dispatch(putRequest(GENERAL_SETTINGS_INFO_URL, 1, params, FETCH_GENERAL_SETTINGS, API_REQUEST_FAILED_ASK_USER_FOR_RETRY))
    }
}

export const userMiddleware = [
    getSettingsProcess,
    modifySettingsProcess,
]