const PREFIX = '[user]'

export const USER_LOADING =     `${PREFIX} USER_LOADING`
export const USER_LOADED =      `${PREFIX} USER_LOADED`
export const AUTH_ERROR =       `${PREFIX} AUTH_ERROR`
export const LOGIN_SUCCESS =    `${PREFIX} LOGIN_SUCCESS`
export const LOGIN_FAIL =       `${PREFIX} LOGIN_FAIL`
export const LOGOUT_SUCCESS =   `${PREFIX} LOGOUT_SUCCESS`
export const REGISTER_SUCCESS = `${PREFIX} REGISTER_SUCCESS`
export const REGISTER_FAIL =    `${PREFIX} REGISTER_FAIL`

export const SET_USER_NAME =    `${PREFIX} SET_USER_NAME`
export const FETCH_GENERAL_SETTINGS = `${PREFIX} FETCH_GENERAL_SETTINGS`
export const UPDATE_GENERAL_SETTINGS = `${PREFIX} UPDATE_GENERAL_SETTINGS`

export const SEND_GENERAL_SETTINGS_TO_SERVER = `${PREFIX} SEND_GENERAL_SETTINGS_TO_SERVER`

export const setUserName = (name) => ({
    type: SET_USER_NAME,
    payload: name,
})

export const fetchGeneralSettings = () => ({
    type: FETCH_GENERAL_SETTINGS,
})

export const sendGeneralSettingsToServer = (data) => ({
    type: SEND_GENERAL_SETTINGS_TO_SERVER,
    payload: data,
})
