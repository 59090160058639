import {
    FETCH_AREAS,
    CREATE_AREA,
    DELETE_AREA,
    MODIFY_AREA,
    UPDATE_AREAS,
    ERROR_FETCHING_AREAS,
    ERROR_CREATING_AREA,
    ERROR_DELETING_AREA,
    ERROR_MODIFYING_AREA,
} from '../actions/areasActions'
import {
    getRequest,
    postRequest,
    deleteRequest,
    putRequest,
    API_REQUEST_FAILED_ASK_USER_FOR_RETRY,
} from '../actions/apiActions'
import {reportServerError} from '../actions/errorActions'

const AREAS_URL = 'areas/areas'

const getAreasProcess = ({dispatch}) => next => action => {
    next(action)
    if (action.type === FETCH_AREAS) {
        dispatch(getRequest(AREAS_URL, UPDATE_AREAS, ERROR_FETCHING_AREAS))
    }
}

const createAreaProcess = ({dispatch}) => next => action => {
    next(action)
    if (action.type === CREATE_AREA) {
        dispatch(postRequest(AREAS_URL, action.payload, FETCH_AREAS, API_REQUEST_FAILED_ASK_USER_FOR_RETRY))
    }
}

const deleteAreaProcess = ({dispatch}) => next => action => {
    next(action)
    if (action.type === DELETE_AREA) {
        dispatch(deleteRequest(AREAS_URL, action.payload, FETCH_AREAS, API_REQUEST_FAILED_ASK_USER_FOR_RETRY))
    }
}

const modifyAreaProcess = ({dispatch}) => next => action => {
    next(action)
    if (action.type === MODIFY_AREA) {
        const {id, params} = action.payload
        dispatch(putRequest(AREAS_URL, id, params, FETCH_AREAS, API_REQUEST_FAILED_ASK_USER_FOR_RETRY))
    }
}

const errorAreasHandlerProcess = ({dispatch}) => next => action => {
    next(action)
    if (action.type === ERROR_CREATING_AREA || action.type === ERROR_DELETING_AREA || action.type === ERROR_DELETING_AREA) {
        alert('Action unsuccessful.')
    } else if (action.type === ERROR_FETCHING_AREAS) {
        // dispatch(reportServerError())
    }
}

export const areasMiddleware = [
    getAreasProcess,
    createAreaProcess,
    deleteAreaProcess,
    modifyAreaProcess,
    errorAreasHandlerProcess,
]