import React, {Component} from 'react'


export default class BaseButton extends Component {
    constructor(props, params) {
        super(props)
        this.state = {
            // button icon / text / customElement
            icon: null,
            iconActive: null,
            text: null,
            val: null,
            customElement: null,
            // button state
            isActive: props.initialVal || props.setActive || false,
            // button styles
            style: null,
            styleActive: null,
            divStyle: null,

            independentRender: false,

            // Update from props and params from child
            ...props,
            ...params
        }
    }
    

    performAction = (isActiveNow) => {
        this.props.action(isActiveNow)
    }

    setCustomElement = (element) => {
        this.setState({customElement: element})
    }

    componentDidUpdate(prevProps) {
        if (this.props.togglerMode && this.props.setActive != prevProps.setActive) {
            this.setState({isActive: this.props.setActive})
        }
    }

    toggle = () => {
        if (this.props.togglerMode) {
            this.props.action(!this.props.setActive)
        } else {
            this.setState(prevState => {
                const isActiveNow = !prevState.isActive
                this.performAction(isActiveNow)
                return {isActive: isActiveNow}
            })
        }
    }

    getCurrentIcon = () => {
        if (this.state.isActive && this.state.iconActive) {
            return this.state.iconActive
        }
        return this.state.icon
    }

    getCurrentStyle = () => {
        if (this.state.isActive && this.state.styleActive) {
            return this.state.styleActive
        }
        return this.state.style
    }

    renderChildren = () => {
        return null
    }

    render(customElement) {
        const icon = this.getCurrentIcon()
        const style = this.getCurrentStyle()
        
        const {superStyle} = this.props
        const disabled = this.props.disabled || false

        let element = customElement || <i className={icon}></i>
        const textStyle = {fontSize: '0.5em', margin: 0}
        const leftText = (this.props.leftText ? <span style={textStyle}>[{this.props.leftText}]</span> : null)
        const rightText = (this.props.rightText ? <span style={textStyle}>[{this.props.rightText}]</span> : null)

        element = <div>{leftText}{element}{rightText}</div>


        let colorStyle = {}
        if (this.state.isActive && this.state.color) {
            colorStyle = {
                color: this.state.color,
            }
        }

        let className = 'default-button'
        
        if (disabled) {
            colorStyle.color = 'gray'
            className = 'disabled-button'
        }
        if (style) {
            className += ' ' + style
        }
        if (superStyle) {
            colorStyle = superStyle
        }

        if (!this.state.independentRender && !this.props.rotate) {
            return (
                <button
                    onClick={this.toggle}
                    className={className} 
                    style={colorStyle}
                    disabled={disabled}
                    value={this.state.isActive}
                >
                    {element}
                    {this.renderChildren()}
                </button>
            )
        } else {
            const theClassName = (this.props.rotate ? 'rotate' : 'div-center-bottom')
            return (
                <div className={theClassName}>
                    <button
                        onClick={this.toggle} 
                        className={className}
                        style={colorStyle}
                        disabled={disabled}
                        value={this.state.isActive}
                    >
                        {element}
                    </button>
                    {this.renderChildren()}
                </div>
            )
        }
    }
}