import moment from 'moment'

const PREFIX = '[devices]'

// Command type actions (middleware only)
export const FETCH_DEVICES = `${PREFIX} GET`

// Document type actions (reducers only)
export const UPDATE_DEVICES = `${PREFIX} UPDATE_DEVICES`
export const FILTER_INACTIVE_DEVICES = `${PREFIX} FILTER_INACTIVE_DEVICES`
export const TOGGLE_SHOW_GPS_TRAILS = `${PREFIX} TOGGLE_SHOW_GPS_TRAILS`
export const HIDE_DEVICE = `${PREFIX} HIDE_DEVICE`
export const HIDE_ALL_DEVICES = `${PREFIX} HIDE_ALL_DEVICES`


// 
export const CREATE_COMMAND = `${PREFIX} CREATE_COMMANDS`
export const UPDATE_COMMANDS = `${PREFIX} UPDATE_COMMANDS`

export const CREATE_COMMAND_SUCCESS = `${PREFIX} CREATE_COMMAND_SUCCESS`
export const CREATE_COMMAND_FAILED = `${PREFIX} CREATE_COMMAND_FAILED`
export const CLEAR_COMMAND_REQ_LOG = `${PREFIX} CLEAR_COMMAND_REQ_LOG`

export const clearCommandLog = () => ({
    type: CLEAR_COMMAND_REQ_LOG
})

// Action creators
export const getDevices = () => ({
    type: FETCH_DEVICES,
})

export const filterInactiveDevices = () => ({
    type: FILTER_INACTIVE_DEVICES,
})

export const hideDevice = (id, hide) => ({
    type: HIDE_DEVICE,
    payload: {
        id,
        hide,
    }
}) 

export const hideAllDevices = (hide) => ({
    type: HIDE_ALL_DEVICES,
    payload: hide
}) 

export const toggleShowGpsTrails = () => ({
    type: TOGGLE_SHOW_GPS_TRAILS,
})

export const createCommand = (deviceId, cmd, token, expirationTime) => ({
    type: CREATE_COMMAND,
    payload: {
        device: deviceId,
        cmd,
        token,
        expiration_date: moment.utc().add(10, 'm'),
    },
})
