import React, {useState} from 'react'

import {Pin} from '../IconComponents/Buttons'

export function usePinning() {
    const [pinned, setPinned] = useState([])
    
    function addPin(id) {
        setPinned(p => [id, ...p])
    }

    function removePin(id) {
        setPinned(p => p.filter(item => item !== id))
    }

    function getPin(id) { 
        return <Pin id={id} addPin={addPin} removePin={removePin}/>
    }

    function sortByPins(array, idName='id') {
        let objectWithArrayElements = {}
        for (const item of array) {
            const id = item[idName]
            objectWithArrayElements[id] = item
        }
        let arraySortedByPins = []
        for (const id of pinned) {
            const element = objectWithArrayElements[id]
            if (element) {
                arraySortedByPins.push(element)
                delete objectWithArrayElements[id]
            }
        }
        let re = Object.values(objectWithArrayElements)
        let remainingElements = []
        if (re.length > 0) {
            remainingElements = re
        }
        arraySortedByPins = [...arraySortedByPins, ...remainingElements]
        return arraySortedByPins
    }

    return [sortByPins, getPin]
}