import React, {useState, useEffect, useRef} from 'react'
import {useSelector, useDispatch} from 'react-redux'
import moment from 'moment'

import {selectDevicesArray, selectDeviceById} from '../redux/reducers/devicesReducer'
import {selectGpsArchiveStore, selectGpsDataById} from '../redux/reducers/gpsArchiveReducer'
import {getGpsDataForDevice, SET_UPDATE_IN_PROGRESS} from '../redux/actions/gpsArchive'
import {setShowArchivalGpsPoints} from '../redux/actions/mapActions'
import {Device} from './Devices'
import { FlexCol, FlexBar } from '../SmallComponents/flexBar'
import * as Buttons from '../IconComponents/Buttons'
import Calendar from 'react-calendar'
import { Toolbar } from '../IconComponents/IconBar'


function DayGpsElement({reload, device, data, day}) {
    const dispatch = useDispatch()
    const [gpsCount, earliestDate, latestDate, points] = data

    function showPointsOnMap() {
        dispatch(setShowArchivalGpsPoints(device, day, points))
    }

    return (
        <div className='device-element'>
            <FlexBar>
                {day} - {gpsCount} GPS locations
                <button onClick={reload}>Download</button>
            </FlexBar>
            <FlexBar>
                <div>
                    From: {earliestDate}
                    <br/>
                    To: {latestDate}
                </div>
                <button disabled={! points || points.length === 0} onClick={showPointsOnMap}>Show on map</button>
            </FlexBar>
        </div>
    )
}

export function GpsCalendar({data, show}) {
    const device = data.id
    const devices = useSelector(selectDevicesArray)
    const selectedDeviceObj = useSelector(selectDeviceById(device))
    const {updateInProgress} = useSelector(selectGpsArchiveStore)
    const gpsDataForDevice = useSelector(selectGpsDataById(device))
    const dispatch = useDispatch()
    const [waitingToShowPoints, setWaitingToShowPoints] = useState(false)
    const [selectedDate, setSelectedDate] = useState(null)

    function updatePointsFromServer() {
        dispatch({type: SET_UPDATE_IN_PROGRESS})
        dispatch(getGpsDataForDevice(device))
    }

    function updatePointsForDay(day) {
        dispatch({type: SET_UPDATE_IN_PROGRESS})
        dispatch(getGpsDataForDevice(device, day))
    }

    function showPointsOnMap(day, points) {
        dispatch(setShowArchivalGpsPoints(device, day, points))
    }

    function dayContainsAmount(day) {
        const positionsForTheDay = gpsDataForDevice[day]
        return !!positionsForTheDay && positionsForTheDay.length > 0
    }

    function dayContainsPointsData(day) {
        const positionsForTheDay = gpsDataForDevice[day]
        return !!positionsForTheDay && positionsForTheDay.length > 1
    }

    function insertNumberIntoCalendarDay({ date, view }) {
        if (view === 'month' && gpsDataForDevice) {
            const formattedDate = moment(date).format('YYYY-MM-DD')
            const positionsForTheDay = gpsDataForDevice[formattedDate]
            
            if (dayContainsAmount(formattedDate)) {
                const color = dayContainsPointsData(formattedDate) ? 'green' : 'black'
                return <p style={{margin: '0', color}}>({positionsForTheDay[0]})</p>
            }
            return null
        }
        return null
    }

    function tileDisabled({ date, view }) {
        if (view === 'month') {
            if (gpsDataForDevice && !updateInProgress) {
                // gpsDataForDevice
                const formattedDate = moment(date).format('YYYY-MM-DD')
                const positionsForTheDay = gpsDataForDevice[formattedDate]
                // <p style={{margin: '0'}}>(0)</p>
                return !positionsForTheDay
            }
                return true
        }
        return false
    }

    function calendarClick(date, event) {
        const formattedDate = moment(date).format('YYYY-MM-DD')
        setSelectedDate(formattedDate)
        const positionsForTheDay = gpsDataForDevice[formattedDate]
        
        if (dayContainsPointsData(formattedDate)) {
            const [gpsCount, earliestDate, latestDate, points] = positionsForTheDay
            showPointsOnMap(date, points)
        } else {
            setWaitingToShowPoints(true)
            updatePointsForDay(formattedDate)
            // showPointsOnMap(date, positionsForTheDay)
        }
    }

    useEffect(() => {
        if (waitingToShowPoints) {
            setWaitingToShowPoints(false)
            const positionsForTheDay = gpsDataForDevice[selectedDate]
            if (positionsForTheDay) {
                const [gpsCount, earliestDate, latestDate, points] = positionsForTheDay
                showPointsOnMap(selectedDate, points)
            }
        }
    }, [gpsDataForDevice])

    let [gpsCount, earliestDate, latestDate, points] = [null, null, null, null]
    if (selectedDate) {
        const positionsForTheDay = gpsDataForDevice[selectedDate]
            if (positionsForTheDay) {
                [gpsCount, earliestDate, latestDate, points] = positionsForTheDay
            }
    }

    return (
        <>
            {show 
                ?   <>
                        <hr/>
                        <FlexBar style={{alignItems: 'flex-start'}}>
                            <Calendar 
                                tileDisabled={tileDisabled}
                                tileContent={insertNumberIntoCalendarDay}
                                onClickDay={calendarClick}
                            />
                        <Toolbar>
                            <Buttons.Refresh action={updatePointsFromServer} rotate={updateInProgress} disabled={updateInProgress} />
                        </Toolbar>
                        </FlexBar>
                        { earliestDate && latestDate
                            ?   <>
                                    <div>
                                        From: {earliestDate}
                                        <br/>
                                        To: {latestDate}
                                    </div>
                                </>
                            : null
                        }
                    </>
                : null
            }
        </>
    )
}