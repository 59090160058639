import React, {useEffect, useRef, useState} from 'react'
import {useSelector, useDispatch} from 'react-redux'

import L from 'leaflet'
import {
    // Map,
    // TileLayer,
    Marker,
    Popup,
    Polyline,
    CircleMarker,
    // Circle,
    Tooltip,
    useLeaflet
} from 'react-leaflet'

import {Device} from '../MainComponents/Devices'

import {scrollToDeviceById} from '../redux/actions/uiActions'
import {selectDeviceById,
    selectFilteredAndNotHiddenDevicesArray,
    selectShowGpsTrails,
} from '../redux/reducers/devicesReducer'
import {selectGpsPositions} from '../redux/reducers/gpsPositionsReducer'
import {selectMapStore} from '../redux/reducers/mapReducer'

import kayakIcon24 from '../static/kayak_border_24.png'
import kayakIcon46 from '../static/kayak_border_46.png'

const kayakS = new L.icon({
    iconUrl: kayakIcon24,
    iconSize:     [24, 24], // size of the icon
    iconAnchor:   [12, 12], // point of the icon which will correspond to marker's location-->
    popupAnchor:  [-30, -26] // point from which the popup should open relative to the iconAnchor
})

const kayakL = new L.icon({
    iconUrl: kayakIcon46,
    iconSize:     [46, 46], // size of the icon
    iconAnchor:   [23, 23], // point of the icon which will correspond to marker's location-->
    // popupAnchor:  [-30, -26] // point from which the popup should open relative to the iconAnchor
})

const STANDARD_PATH_COLOR = 'rgba(90,150,230,0.35)'
const STANDARD_CIRCLE_COLOR = 'rgba(90,150,230,1)'

const SELECTED_PATH_COLOR = 'rgba(40,166,74,0.55)'
const SELECTED_CIRCLE_COLOR = 'rgba(40,166,74,1)'


export function KayakPath({device, measurements, focusOnMap=false, trackOnMap=false, focusOnMapWhenMeasurementsLoaded=false}) {
    const dispatch = useDispatch()
    const deviceData = useSelector(selectDeviceById(device))
    const pathRef = useRef(null)
    const pointsRefs = useRef([])
    pointsRefs.current = []
    const showGpsTrail = useSelector(selectShowGpsTrails)

    const polylinePoints = []
    let lastPosition = null
    const {map} = useLeaflet()
    const zoom = map.getZoom()

    const addToRefs = el => {
        if (el && !pointsRefs.current.includes(el)) {
            pointsRefs.current.push(el);
        }
    }

    const locationComponents = measurements.map((point, idx) => {
        const position = [point.latitude, point.longitude]
        polylinePoints.push(position)
        if (!lastPosition) {
            lastPosition = position
        }
        if (zoom < 15) { return null }
        return (
            <CircleMarker
                key={point.id}
                color={getCircleColor()}
                radius={6}
                center={position}
                ref={addToRefs}
            >
                <Tooltip className='mod-leaflet-popup' >
                    <div>
                        Satellites: {point.visible_satellites}
                        <br/>
                        Lat: {position[0]} Lng: {position[1]}
                        <br/>
                        {point.date}
                    </div>
                </Tooltip>
            </CircleMarker>
        )
    })

    useEffect(() => {
        if (focusOnMap && lastPosition) {
            map.setView(lastPosition)
        }
    }, [focusOnMap])

    useEffect(() => {
        if (focusOnMap && trackOnMap && lastPosition) {
            map.setView(lastPosition)
        }
    }, [focusOnMap, trackOnMap, lastPosition])

    useEffect(() => {
        if (focusOnMapWhenMeasurementsLoaded && lastPosition) {
            map.setView(lastPosition)
        }
    }, [focusOnMapWhenMeasurementsLoaded, measurements])

    // useEffect(() => {
    //     if (focusOnMap && lastPosition) {
    //         setColor(SELECTED_PATH_COLOR)
    //     } else {
    //         setColor(STANDARD_PATH_COLOR)
    //     }
    // }, [focusOnMap, lastPosition])

    function getPathColor() {
        if (focusOnMap && lastPosition) {
            return SELECTED_PATH_COLOR
        } else {
            return STANDARD_PATH_COLOR
        }
    }

    function getCircleColor() {
        if (focusOnMap && lastPosition) {
            return SELECTED_CIRCLE_COLOR
        } else {
            return STANDARD_CIRCLE_COLOR
        }
    }

    // function setColor(color) {
    //     if (pathRef.current) {
    //         pathRef.current.setStyle({color: color})
    //     }
    // }

    const lastPositionMarker = (
        <Marker icon={kayakL} position={lastPosition} autoPan={false}>
            <Popup className='mod-leaflet-popup' onOpen={() => dispatch(scrollToDeviceById(deviceData.id))}>
                <Device data={deviceData} popupMode={[true, 0]} />
            </Popup>
        </Marker>
    )

    return (
        <>
            {showGpsTrail && zoom > 10
                && <Polyline ref={pathRef} color={getPathColor()} positions={polylinePoints} weight={10}/>
            }
            {showGpsTrail && locationComponents}
            {lastPosition && lastPositionMarker}
        </>
    )
}

export function Kayaks() {
    const gpsPositionsByDevice = useSelector(selectGpsPositions)
    const devices = useSelector(selectFilteredAndNotHiddenDevicesArray)
    const {name: centeringObjName, id: centeringObjId, tracking} = useSelector(selectMapStore).centeringResponsibleObject
    const trackDevice = centeringObjName === 'device'

    const kayakPathsComponents =  devices.map(oneDevice => {
        const device = oneDevice.id
        const measurements = gpsPositionsByDevice[device]
        return (
            <KayakPath
                    key={device}
                    device={device}
                    measurements={measurements}
                    focusOnMap={trackDevice && centeringObjId === device}
                    trackOnMap={tracking}
            />
        )
    })

    return kayakPathsComponents
}