import {
    FETCH_DEVICES,
    UPDATE_DEVICES,
    CREATE_COMMAND,
    CREATE_COMMAND_SUCCESS,
    CREATE_COMMAND_FAILED,
} from '../actions/devicesActions'
import {
    getRequest,
    postRequest,
    deleteRequest,
    API_REQUEST_FAILED_ASK_USER_FOR_RETRY,
} from '../actions/apiActions'

const DEVICES_URL = 'devices'
const COMMANDS_URL = 'commands'

const getDevicesProcess = ({dispatch}) => next => action => {
    next(action)
    if (action.type === FETCH_DEVICES) {
        dispatch(getRequest(DEVICES_URL, UPDATE_DEVICES, null))
    }
}

const createCommandProcess = ({dispatch}) => next => action => {
    // console.log('Creating command')
    // console.log(action.payload)
    next(action)
    if (action.type === CREATE_COMMAND) {
        dispatch(postRequest(COMMANDS_URL, action.payload, CREATE_COMMAND_SUCCESS, CREATE_COMMAND_FAILED))
    }
}

export const devicesMiddleware = [
    getDevicesProcess,
    createCommandProcess,
]