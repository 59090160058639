import React, { useEffect, useRef, useState } from 'react'
import {useSelector, useDispatch} from 'react-redux'

import L from 'leaflet'
import {
    // Map,
    // TileLayer,
    Marker,
    Popup,
    // Polyline,
    // CircleMarker,
    Circle,
    Tooltip,
    useLeaflet
} from 'react-leaflet'

import {Area} from '../MainComponents/Areas'

// REDUX ACTIONS
import {modifyArea} from '../redux/actions/areasActions'
import {scrollToAreaById} from '../redux/actions/uiActions'
// REDUCERS
import {selectAreasStore} from '../redux/reducers/areasReducer'
import {selectMapStore} from '../redux/reducers/mapReducer'


const areaIcon = new L.DivIcon({
    className: 'upperclass',
    html: '<div class="lowerclass"><i class="fas fa-arrows-alt"></i></div>',
    iconSize:     [26, 26], // size of the icon
    iconAnchor:   [13, 13], // point of the icon which will correspond to marker's location-->
    // popupAnchor:  [-30, -26] // point from which the popup should open relative to the iconAnchor
})

const horizontalArrowsIcon = new L.DivIcon({
    className: 'upperclass',
    html: '<div class="lowerclass"><i class="fas fa-arrows-alt-h"></i></div>',
    iconSize:     [26, 26], // size of the icon
    iconAnchor:   [13, 13], // point of the icon which will correspond to marker's location-->
    // popupAnchor:  [-30, -26] // point from which the popup should open relative to the iconAnchor
})

function getDistance(pointA, pointB) {
    return new L.latLng(pointA).distanceTo(pointB)
}

function translatePointByMeters(point, latTranslation=0, lngTranslation=0) {
    const latPointTranslated = new L.latLng(point).toBounds(latTranslation*2)
    const lngPointTranslated = new L.latLng(point).toBounds(lngTranslation*2)
    const latPosition = (latTranslation >= 0 ? latPointTranslated._northEast.lat : latPointTranslated._southWest.lat)
    const lngPosition = (lngTranslation >= 0 ? lngPointTranslated._northEast.lng : lngPointTranslated._southWest.lng)
    return [latPosition, lngPosition]
}

export function AreaOnMap({data, focusOnMap=false, showLabels}) {
    const {
        id,
        radius,
        latitude,
        longitude,
        name,
        sound_message_name: soundFile} = data

    const dispatch = useDispatch()
    const INITIAL_RADIUS = radius
    const [draggable, setDraggable] = useState(true)
    const [position, setPosition] = useState([latitude, longitude])
    const refmarker = useRef(null)
    const [controlRadius, setControlRadius] = useState(INITIAL_RADIUS)
    const {map} = useLeaflet()
    const [horizontalMarkerLng, setHorizontalMarkerLng] = useState(translatePointByMeters(position, 0, INITIAL_RADIUS)[1])
    const controlRadiusRef = useRef(null)
    const popupRef = useRef(null)
    const [unlocked, setUnlocked] = useState(false)

    useEffect(() => {
        if (focusOnMap) {
            map.setView(position)
        }
    }, [focusOnMap])

    useEffect(() => {
        if (radius !== controlRadius) {
            setControlRadius(radius)
        }
        if (latitude != position[0] || longitude != position[1]) {
            setPosition([latitude, longitude])
        }
    }, [radius, latitude, longitude])

    useEffect(() => {
        setHorizontalMarkerLng(translatePointByMeters(position, 0, controlRadius)[1])
    }, [controlRadius, position])

    function updatePosition() {
        const marker = refmarker.current
        if (marker != null) {
            const newPos = marker.leafletElement.getLatLng()
            setPosition([newPos.lat, newPos.lng])
            setHorizontalMarkerLng(translatePointByMeters(newPos, 0, controlRadius)[1])
        }
    }

    function updateControlRadius() {
        const marker = controlRadiusRef.current
        if (marker != null) {
            const newPos = marker.leafletElement.getLatLng()
            const newRadius = getDistance(position, [position[0], newPos.lng])
            setControlRadius(Math.abs(Math.round(newRadius)))
            setHorizontalMarkerLng(newPos.lng)
        }
    }

    function sendPositionToServer() {
        dispatch(modifyArea(id, {
            ...data,
            radius: controlRadius,
            latitude: position[0],
            longitude: position[1],
        }))
    }

    function radiusFinalUpdate() {
        updateControlRadius()
        setTimeout(sendPositionToServer, 0)
    }

    function positionFinalUpdate() {
        updatePosition()
        setTimeout(sendPositionToServer, 0)
    }

    function areaClicked() {
        dispatch(scrollToAreaById(data.id))
    }

    const areaColor = focusOnMap ? '#f3c90e' : '#3388ff'
    let areaLabelTooltip = null
    if (showLabels) {
        areaLabelTooltip = (
            <Tooltip
                permanent={true}
                direction='left'>
                    Name: {name}
                    <br/>
                    File: {soundFile}
            </Tooltip>
        )
    }

    const circleEdit = (
        <>
            <Marker
                icon={horizontalArrowsIcon}
                ref={controlRadiusRef}
                draggable={true}
                onDrag={updateControlRadius}
                onDragend={radiusFinalUpdate}
                position={[position[0], horizontalMarkerLng]}
            >
                <Tooltip permanent={true} offset={[10, 0]} direction='right'>
                        {controlRadius}m
                        <br/>
                        Lat: {parseFloat(position[0]).toFixed(5)}
                        <br/>
                        Lng: {parseFloat(position[1]).toFixed(5)}
                </Tooltip>
            </Marker>
            <Marker
                icon={areaIcon}
                draggable={draggable}
                onDrag={updatePosition}
                onDragend={positionFinalUpdate}
                position={position}
                ref={refmarker}>
            </Marker>
        </>
    )

    return (
        <Circle
            center={position}
            radius={controlRadius}
            color={areaColor}
        >
            <Popup className='mod-leaflet-popup' onOpen={areaClicked}>
                <Area
                    data={data}
                    toggleLock={setUnlocked}
                    unlocked={unlocked}
                    textEditable={false}
                />
            </Popup>
            {unlocked ? circleEdit : areaLabelTooltip}
        </Circle>
    )
}

export function AreasOnMap() {
    const {areas, showAreas, showAreasLabels} = useSelector(selectAreasStore)
    const {name: centeringObjName, id: centeringObjId, tracking} = useSelector(selectMapStore).centeringResponsibleObject
    const trackArea = centeringObjName === 'area'

    let areaComponents = null
    if (showAreas) {
        areaComponents = areas.map(area => {
            return <AreaOnMap key={area.id} data={area} focusOnMap={trackArea && centeringObjId === area.id} showLabels={showAreasLabels} />
        })
    }
    return areaComponents
}