const PREFIX = '[gps archive]'

// Command type actions (middleware only)
export const GET_GPS_DATA_FOR_DEVICE = `${PREFIX} GET_GPS_DATA_FOR_DEVICE`
export const SET_UPDATE_IN_PROGRESS = `${PREFIX} SET_UPDATE_IN_PROGRESS`

// Document type actions (reducers only)
export const UPDATE_GPS_DATA = `${PREFIX} UPDATE_GPS_DATA`

export const SERVER_COM_FAILED = `${PREFIX} SERVER_COM_FAILED`

export const getGpsDataForDevice = (id, day=null) => ({
    type: GET_GPS_DATA_FOR_DEVICE,
    payload: {
        id,
        day,
    }
})
