export const CHECK_SOUND = "python3 /root/kayak-reporting/play_percent.py"
export const PLAY_MUSIC = "python3 /root/kayak-reporting/play_percent.py"
export const DEVICE_IDENTIFICATION = "./root/kayak-reporting/identify.py"
export const PWD = "pwd"
export const RESTART_ALL_SERVICES = "./root/kayak-reporting/scripts/systemd_restart_services.sh"
export const CHECK_SERVICES_STATUSES = "./root/kayak-reporting/scripts/systemd_status_services.sh"
export const HOSTNAME = "hostname"
export const UPDATE_FIRMWARE = "./root/kayak-reporting/update_repo.sh"
export const WIFI_ON = "nmcli c up Gordo"
export const WIFI_OFF = "nmcli c down Gordo"
export const MODEM_ON = "./root/kayak-reporting/bash_scripts/modem_on.sh"
export const MODEM_OFF = "./root/kayak-reporting/bash_scripts/modem_off.sh"
export const RESTART_DEVICE = "reboot"
export const POWER_OFF_DEVICE = "poweroff"