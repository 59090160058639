import moment from 'moment'

import {
    UPDATE_DEVICES,
    UPDATE_COMMANDS,
    FILTER_INACTIVE_DEVICES,
    TOGGLE_SHOW_GPS_TRAILS,
    HIDE_DEVICE,
    HIDE_ALL_DEVICES,
    CREATE_COMMAND_SUCCESS,
    CREATE_COMMAND_FAILED,
    CLEAR_COMMAND_REQ_LOG,
} from '../actions/devicesActions'

const filterInactiveDevicesOptions = [
    2,
    null,
    60,
    null,
    120,
    null,
]

const initial_state = {
    showGpsTrails: false,
    filterInactiveDevices: 0,
    hiddenDevices: [], // list of hidden devices ids

    devices: [],
    devicesWithErrors: 0,
    commands: {
        // _deviceId_: [...commandsHistory]
    },
    commandsRequestLog: [],
}

function parseReportInDevice(deviceData) {
    const report = JSON.parse(deviceData.last_report.report)
    // return deviceData
    return {
        ...deviceData,
        last_report: {
            ...deviceData.last_report,
            report,
        }
    }
}

function parseReportsInDevices(devicesData) {
    return devicesData.map(data => parseReportInDevice(data))
}

function getNumOfDevicesWithErrors(devices) {
    return devices.filter(device => device.error_count > 0).length
}

function hideOrShow(array, hide=true, idsArray) {
    if (hide) {
        return [...array, ...idsArray.filter(id => !array.includes(id))]
    } else {
        return array.filter(id => !idsArray.includes(id))
    }
}

export function devicesReducer(state=initial_state, action) {
    switch(action.type) {
        case UPDATE_DEVICES:
            const devices = parseReportsInDevices(action.payload)
            return {
                ...state,
                devices,
                devicesWithErrors: getNumOfDevicesWithErrors(devices)
            }
        case FILTER_INACTIVE_DEVICES:
            const nextOption = state.filterInactiveDevices + 1
            const nextOptionLimited = (nextOption >= filterInactiveDevicesOptions.length ? 0 : nextOption)
            return {
                ...state,
                filterInactiveDevices: nextOptionLimited,
            }
        case TOGGLE_SHOW_GPS_TRAILS:
            return {
                ...state,
                showGpsTrails: !state.showGpsTrails,
            }
        case HIDE_ALL_DEVICES:
            const {filterInactiveDevices} = state
            const filterOption = filterInactiveDevicesOptions[filterInactiveDevices]
            const allCurrentlyVisibleDevices = filterDevices(filterOption, state).map(device => device.id)
            return {
                ...state,
                hiddenDevices: hideOrShow(state.hiddenDevices, action.payload, allCurrentlyVisibleDevices)
            }
        case HIDE_DEVICE:
            const {id, hide} = action.payload
            return {
                ...state,
                hiddenDevices: hideOrShow(state.hiddenDevices, hide, [id])
            }
        case CREATE_COMMAND_SUCCESS:
            return {
                ...state,
                commandsRequestLog: [...state.commandsRequestLog, true]
            }
        case CREATE_COMMAND_FAILED:
            return {
                ...state,
                commandsRequestLog: [...state.commandsRequestLog, false]
            }
        case CLEAR_COMMAND_REQ_LOG:
            return {
                ...state,
                commandsRequestLog: [],
            }
        case UPDATE_COMMANDS:
            return {
                ...state,
                commands: {
                    ...state.commands,
                    [action.payload.id]: action.payload.commands,
                } 
            }
        default:
            return state
    }
}

function selectFilteredDevices(state) {
    const filterInactiveDevices = selectFilterInactiveDevices(state)
    const localStore = selectDevicesStore(state)
    return filterDevices(filterInactiveDevices, localStore)
}

function filterDevices(filterInactiveDevices, localStore) {
    const {devices} = localStore
    if (!filterInactiveDevices) {
        return devices
    }
    return devices.filter(device => {
        const timestamp = moment(device.last_report.timestamp_sent)
        const ageOfReport = moment().diff(timestamp, 'seconds')
        return ageOfReport <= filterInactiveDevices * 60
    })
}

export const selectDevicesStore = state => state.devices
export const selectDevicesArray = state => selectDevicesStore(state).devices
export const selectFilteredDevicesArray = state => selectFilteredDevices(state) 
export const selectFilteredAndNotHiddenDevicesArray = state => selectFilteredDevicesArray(state).filter(device => !selectHiddenDevices(state).includes(device.id))
export const selectDeviceById = id => state => selectDevicesArray(state).find(device => device.id == id) || null
export const selectCommandsById = id => state => selectDevicesStore(state).commands[id] || []
export const selectFilterInactiveDevices = state => filterInactiveDevicesOptions[selectDevicesStore(state).filterInactiveDevices]
export const selectHiddenDevices = state => selectDevicesStore(state).hiddenDevices
export const selectShowGpsTrails = state => selectDevicesStore(state).showGpsTrails
export const selectDevicesWithErrors = state => selectDevicesStore(state).devicesWithErrors
