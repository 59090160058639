import React, {useState, useEffect, useRef} from 'react'
import {useSelector, useDispatch} from 'react-redux'

// REDUX
import {setCenteringResponsibleObject, centerMapHere} from '../redux/actions/mapActions'
import {
    createArea,
    deleteArea,
    modifyArea,
    toggleShowAreas, 
    toggleShowAreasLabels,
} from '../redux/actions/areasActions'
import {
    selectAreasStore,
    selectAreas,
} from '../redux/reducers/areasReducer'
import {scrollToAreaById} from '../redux/actions/uiActions'
import {selectUiStore} from '../redux/reducers/uiReducer'
import {selectMapStore} from '../redux/reducers/mapReducer'

import {FlexBar} from '../SmallComponents/flexBar'
import * as Buttons from '../IconComponents/Buttons'
import { Toolbar } from '../IconComponents/IconBar'


function InputTextControl({name, syncFunction, initalValue, textEditable=true, selectField=false}) {
    const [unlocked, setUnlocked] = useState(false)
    const [currentValue, setCurrentValue] = useState(initalValue)

    useEffect(() => {
        setCurrentValue(initalValue)
    }, [initalValue])

    function save() {
        syncFunction(currentValue)
        setUnlocked(false)
    }

    function reject() {
        setCurrentValue(initalValue)
        setUnlocked(false)
    }

    const label = (
        <>
            {currentValue}
            {textEditable
                ? <Buttons.Rename action={() => setUnlocked(true)} />
                : null
            }
        </>
    )

    function getInputField() {
        if (selectField) {
            return (
                <select onChange={event => setCurrentValue(event.target.value)}>
                    {selectField.map((file, idx) => (
                        <option
                            key={idx}
                            value={file}
                        >{file}</option>
                    ))}
                </select>
            )
        } else {
            return (
                <input
                    type='text'
                    value={currentValue}
                    onChange={event => setCurrentValue(event.target.value)}
                />
            )
        }
    } 

    return (
        <FlexBar>
            {name + ': '}
            {unlocked
                ?   <>
                        {getInputField()}
                        <Buttons.Save action={save} />
                        <Buttons.Reject action={reject} />
                    </>
                : label}
        </FlexBar>
    )
}

const audioFilesList = ['-', 'riders', 'meltdown', 'crows', 'danger', 'thunder', 'engine', 'cricket']

export function Area({data, toggleLock, textEditable=true, scrollTo=false, isSelected=false}) {
    const dispatch = useDispatch()
    const [unlocked, setUnlocked] = useState(false)
    const {name, sound_message_name: soundFile} = data
    const refForFocus = useRef(null)

    function innerToggleUnlocked(state) {
        if (toggleLock) { toggleLock(state) }
        setUnlocked(state)
    }

    useEffect(() => {
        if (scrollTo) {
            refForFocus.current.scrollIntoView({ behavior: 'smooth', block: 'start' })
        }
    }, [scrollTo])

    function confirmDeletion() {
        let confirmed = window.confirm('Confirm deleting area.')
        if (confirmed) {
            dispatch(deleteArea(data.id))
        }
    }

    function changeAreaName(name) {
        dispatch(modifyArea(data.id, {...data, name: name}))
    }

    function changeAreaSoundFile(fileName) {
        dispatch(modifyArea(data.id, {...data, sound_message_name: fileName}))
    }

    let classNames = 'trip-element'
    if (isSelected) {
        classNames += ' show-focus'
    }

    function focusOnThisArea() {
        dispatch(setCenteringResponsibleObject('area', data.id))
    }

    return (
        <div 
            onClick={focusOnThisArea}
            className={classNames}
            style={{padding: '0.25rem'}}
            ref={refForFocus}
        >
            <FlexBar>
                <InputTextControl
                    name='Name'
                    syncFunction={changeAreaName}
                    initalValue={name}
                    textEditable={textEditable}
                />
                {toggleLock
                    ? <Buttons.Lock action={innerToggleUnlocked} />
                    : null
                }
            </FlexBar>
            <FlexBar>
                <InputTextControl
                    name='Audio'
                    selectField={audioFilesList}
                    syncFunction={changeAreaSoundFile}
                    initalValue={soundFile}
                    textEditable={textEditable}
                />
                <Buttons.Delete action={confirmDeletion} />
            </FlexBar>
        </div>
    )
}

export function Areas() {
    const dispatch = useDispatch()
    const {areas, showAreas, showAreasLabels} = useSelector(selectAreasStore)
    const {scrollTo} = useSelector(selectUiStore)
    const mapInfo = useSelector(selectMapStore)
    const numOfAreas = areas.length
    const {name: centeringObjName, id: centeringObjId, tracking} = useSelector(selectMapStore).centeringResponsibleObject


    let areaToScrollTo = null
    if (scrollTo['area']) {
        areaToScrollTo = scrollTo['area']
    }

    const AreasComponents = areas.map(area => {
        return (
            <Area
                key={area.id}
                data={area}
                scrollTo={area.id == areaToScrollTo}
                isSelected={centeringObjName === 'area' && centeringObjId === area.id}
                showLabels={showAreasLabels}
            />
        )
    })

    function createNewArea() {
        // FIXME catch errors
        const center = mapInfo.mapObject.leafletElement.getCenter()
        dispatch(createArea({
            name: 'new-area',
            sound_message_name: '-',
            latitude: center.lat,
            longitude: center.lng,
            radius: 1000,
            is_circle: true,
        }))
    }

    return (
        <div className='big-toolbar-container'>
            <div className='trip-title'>
                <FlexBar>
                    <h2>All areas: {numOfAreas || 'sync to view'}</h2>

                    <Toolbar>
                        <Buttons.Hide togglerMode={true} setActive={showAreas} action={() => dispatch(toggleShowAreas())} />
                        <Buttons.HideLabels disabled={!showAreas} togglerMode={true} setActive={showAreasLabels} action={() => dispatch(toggleShowAreasLabels())} />
                        <Buttons.Create action={createNewArea}/>
                        <div style={{marginRight:'10px'}}></div>
                    </Toolbar>
                </FlexBar>
            </div>
            <div className='scrollable-big-toolbar'>
                {AreasComponents}
            </div>
        </div>
    )

    // return (
    //     <div className='scrollable-big-toolbar'>
    //         <div className='trip-element trip-title'>
    //         <FlexBar>
                
    //             <h2>All areas: {numOfAreas || 'sync to view'}</h2>
    //             {toolbar}
    //         </FlexBar>
    //         </div>
    //         {AreasComponents}
    //      </div>
    // )
}