import {
    FETCH_TRIPS,
    CREATE_TRIP,
    UPDATE_TRIPS,
    MODIFY_TRIP,
    ERROR_FETCHING_TRIPS,
    ERROR_CREATING_TRIP,
    ERROR_DELETING_TRIP,
    ERROR_RENAMING_TRIP,
    DELETE_TRIP,
} from '../actions/tripsActions'
import {
    getRequest,
    postRequest,
    deleteRequest,
    putRequest,
} from '../actions/apiActions'

const TRIPS_URL = 'trips'

const getTripsProcess = ({dispatch}) => next => action => {
    next(action)
    if (action.type === FETCH_TRIPS) {
        dispatch(getRequest(TRIPS_URL, UPDATE_TRIPS, ERROR_FETCHING_TRIPS))
    }
}

const createTripsProcess = ({dispatch}) => next => action => {
    next(action)
    if (action.type === CREATE_TRIP) {
        dispatch(postRequest(TRIPS_URL, action.payload, FETCH_TRIPS, ERROR_CREATING_TRIP))
    }
}

const deleteTripsProcess = ({dispatch}) => next => action => {
    next(action)
    if (action.type === DELETE_TRIP) {
        dispatch(deleteRequest(TRIPS_URL, action.payload, FETCH_TRIPS, ERROR_DELETING_TRIP))
    }
}

const modifyTripProcess = ({dispatch}) => next => action => {
    next(action)
    if (action.type === MODIFY_TRIP) {
        const {id} = action.payload
        dispatch(putRequest(TRIPS_URL, id, action.payload, FETCH_TRIPS, ERROR_RENAMING_TRIP))
    }
}

export const tripsMiddleware = [
    getTripsProcess,
    createTripsProcess,
    deleteTripsProcess,
    modifyTripProcess,
]