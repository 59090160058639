const PREFIX = '[server]'

// Command type actions (middleware only)
export const FETCH_INFO = `${PREFIX} GET`
export const DISPATCH_INFO_TO_SECTIONS = `${PREFIX} DISPATCH_INFO_TO_SECTIONS`

// Document type actions (reducers only)
export const SET_GPS_FILTERING = `${PREFIX} SET_GPS_FILTERING`
export const SET_DEVICES_FILTERING = `${PREFIX} SET_DEVICES_FILTERING`
export const SET_LAST_UPDATE_DATE = `${PREFIX} SET_LAST_UPDATE_DATE`
export const SET_AUTO_UPDATE = `${PREFIX} SET_AUTO_UPDATE`
export const START_UPDATE = `${PREFIX} START_UPDATE`
export const SET_UPDATE_IN_PROGRESS = `${PREFIX} SET_UPDATE_IN_PROGRESS`
export const SET_UPDATE_RESULT = `${PREFIX} SET_UPDATE_RESULT`

export const ERROR_GETTING_UPDATE = `${PREFIX} ERROR_GETTING_UPDATE`

export const getServerInfo = () => ({
    type: FETCH_INFO,
})

export const setUpdateInProgress = () => ({
    type: SET_UPDATE_IN_PROGRESS,
})

export const setAutoUpdate = (automaticUpdates) => ({
    type: SET_AUTO_UPDATE,
    payload: automaticUpdates,

})

export const startUpdate = () => ({
    type: START_UPDATE,
})

export const setUpdateResult = (lastUpdateSuccessful, lastUpdateDate, lastUpdateError) => ({
    type: SET_UPDATE_RESULT,
    payload: {
        lastUpdateSuccessful,
        lastUpdateDate,
        lastUpdateError,
        updateInProgress: false,
    },
})

export const setGpsFiltering = (value) => ({
    type: SET_GPS_FILTERING,
    payload: {
        value,
    }
})

export const setDevicesFiltering = (mode, list) => ({
    type: SET_DEVICES_FILTERING,
    payload: {
        mode,
        list,
    }
})