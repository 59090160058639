import {
    SET_MAP_OBJECT,
    SET_SHOW_ARCHIVAL_GPS_POINTS,
    SHOW_LIVE_DEVICES_AND_AREAS,
    SET_CENTERING_RESPONSIBLE_OBJ,
} from '../actions/mapActions'

const initialState = {
    mapObject: null,
    showLiveDevices: true,

    showArchivalGpsPoints: false,
    archivalGpsSpec: {
        device: null,
        day: null,
        points: [],
    },

    centerMapHere: null,
    centeringResponsibleObject: {
        name: '',
        id: '',
        tracking: false,
    }
}

export function mapReducer(state = initialState, action) {
    switch(action.type) {
        case SET_MAP_OBJECT:
            return {
                ...state,
                mapObject: action.payload,
            }
        case SET_SHOW_ARCHIVAL_GPS_POINTS:
            return {
                ...state,
                showLiveDevices: false,
                showAreas: false,
                showArchivalGpsPoints: true,
                archivalGpsSpec: action.payload,
            }
        case SHOW_LIVE_DEVICES_AND_AREAS:
            return {
                ...state,
                showLiveDevices: true,
                showAreas: true,
                showArchivalGpsPoints: false,
            }
        case SET_CENTERING_RESPONSIBLE_OBJ:
            return {
                ...state,
                centeringResponsibleObject: action.payload,
            }
        default: 
            return state
    }
}

export const selectMapStore = state => state.map
