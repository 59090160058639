import {UPDATE_GPS_POSITIONS} from '../actions/gpsPositionsActions'


export function gpsPositionsReducer(state={}, action) {
    switch(action.type) {
        case UPDATE_GPS_POSITIONS:
            return action.payload
        default:
            return state
    }
}

export const selectGpsPositions = state => state.gps