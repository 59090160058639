import React from 'react'
import { CircularProgressbar } from 'react-circular-progressbar';

import {FlexBar, FlexCol} from '../SmallComponents/flexBar'
import {getTimezoneFromString} from '../timezones/timezones'
import './style.css'


export function IndicatorSignal() {
    return(
        <i style={{color: 'green'}} className="fas fa-signal"></i>
    )
}

export function IndicatorLastTransmission({val='-', style}) {

    return(
        <FlexBar style={style}>
            <div style={{fontSize: '0.8rem'}}>{val}</div>
            {/* {connectionOk
                ? <i style={{color: 'green'}} className="far fa-check-circle"> Connected</i>
                : <i style={{color: 'red'}} className="far fa-times-circle">No connection</i>
            } */}
        </FlexBar>
    )
}

export function IndicatorConnection({hasFix=false}) {
    const color = (hasFix ? 'green' : 'gray')
    return(
        <i style={{color}} className="fas fa-satellite-dish"></i>
    )
}

export function IndicatorSatellites({hasFix=false}) {
    const style = {
        color: (hasFix ? 'green' : '#4d4d4d'),
        // fontSize: (hasFix ? '1.2em' : '1em'),
    }
    return(
        <i style={style} className="fas fa-satellite"></i>
    )
}

export function IndicatorBattery({val=-1}) {
    let level = 'empty'
    if (val<10) {
        level = 'empty'
    } else if (val<35) {
        level = 'quarter'
    } else if (val<60) {
        level = 'half'
    } else if (val<80) {
        level = 'three-quarters'
    } else {
        level = 'full'
    }

    return(
        <i className={`fas fa-battery-${level}`}></i>
    )
}

export function IndicatorTemperature({val=-1}) {
    let color = 'green'
    if (val<15) {
        color = 'blue'
    } else if (val<40) {
        color = 'black'
    } else {
        color = 'red'
    }
    return(
        <i style={{color}} className="fas fa-thermometer-half"> {val}&#8451;</i>
    )
}

export function IndicatorRamUsage({val='-'}) {
    return(
        <i className="fas fa-memory"> {val}%</i>
    )
}


export function IndicatorDiskSpace({val='-'}) {
    return(
        <i className="far fa-save"> {val}%</i>
    )
}

export function IndicatorCpuUsage({val='-'}) {
    return(
        <i className="fas fa-microchip"> {val}%</i> 
    )
}

export function IndicatorTimer({val='-'}) {
    return(
      <i className="fas fa-stopwatch">{val}</i>
    )
}

export function IndicatorCircularProgress({timer, timerLimit, text}) {
    return (
        <CircularProgressbar
            value={timer} 
            // text={`${timerLimit - timer}/${timerLimit}`}
            text={text}
            maxValue={timerLimit}
            strokeWidth='13'  
            styles={{
            root: {
                width: '2rem',
                height: '2rem'
            },
            text: {
                fontSize: '24px',
            },
            path: {
                transition: 'none'
            }
                    // timer === 0 ? "none" : "stroke-dashoffset 0.5s ease 0s",
            }}
        />
    )
}