import React, {useState, useEffect, useRef} from 'react'
import moment from 'moment'
import 'react-circular-progressbar/dist/styles.css';

import {FlexBar} from '../SmallComponents/flexBar'
import * as Buttons from '../IconComponents/Buttons'


// REDUX
import {useSelector, useDispatch} from 'react-redux';
import {setAutoUpdate, startUpdate} from '../redux/actions/serverUpdatesActions'
import {selectServerUpdatesStore} from '../redux/reducers/serverUpdatesReducer'
import {selectShowDeviceDetails} from '../redux/reducers/uiReducer'
import {selectErrorStore} from '../redux/reducers/errorReducer'
import {selectApiStore} from '../redux/reducers/apiReducer'
import {showLiveDevicesAndAreas} from '../redux/actions/mapActions'
import {selectMapStore} from '../redux/reducers/mapReducer'




const WH_BG = 'rgba(255,255,255,0.85)'
const NAV_B_STYLE = 'nav-button'

function UserBar({setDisplayDevPanel, toggleUserSettings}) {
  let {name, isSuperUser} = useSelector(state => state.user)
  const {lastReqDuration, last50req} = useSelector(selectApiStore)
  const {appErrors, serverErrors, devicesErrors} = useSelector(selectErrorStore)
  isSuperUser = true
  const userLevels = [
    '',
    '-ninja',
    '-tie',
    '-astronaut',
  ]
  const avg50 = last50req.reduce((a,b) => a + b, 0) / last50req.length
  const styleColor = (isSuperUser ? {color: '#7909ba'} : {})
  styleColor['fontSize'] = '1.5rem'

  const totalErrorsNum = devicesErrors.numOfErrors + appErrors.numOfErrors + serverErrors.numOfErrors
  
  return (
    <NaviTrapezoid right={false} color={WH_BG}>
      {isSuperUser
        ? <>
            <Buttons.Errors rightText={totalErrorsNum || null} numOfErrors={totalErrorsNum} action={setDisplayDevPanel}/>
            <Buttons.Performance action={setDisplayDevPanel}/>
            {/* ReqDT: {lastReqDuration} AVG: {Math.trunc(avg50)} MAX: {Math.max(...last50req)} */}
          </>
        : null
      }
      <Buttons.SettingsUser action={toggleUserSettings} style={NAV_B_STYLE} />
    </NaviTrapezoid>
  )

  //   return (
  //   <NaviTrapezoid right={false} color={WH_BG}>
  //     <InfoBar>
  //       <i className="fas fa-exclamation-triangle"></i>
  //       { isSuperUser
  //         ? <i style={styleColor} className='fas fa-user-ninja'></i>
  //         : <i style={styleColor} className='fas fa-user-circle'></i>
  //       }
  //       {name}
  //       { isSuperUser ? ` E:${errorCounter}` : ''}
  //     </InfoBar>
  //     <ButtonLogout style={NAV_B_STYLE} />
  //   </NaviTrapezoid>
  // )
}


function useTimer(totalSeconds, triggerCallback, ticksPerSecond=1) {
  const [currentTick, setCurrentTick] = useState(0)
  const [isTicking, setIsTicking] = useState(false)
  const totalTicks = totalSeconds * ticksPerSecond
  // const [isThereTimeout, setIsThereTimeout] = useState(false)

  const isTickingRef = useRef(isTicking)
  isTickingRef.current = isTicking

  function tick() {
    setCurrentTick(p => p + 1)
    if (isTickingRef.current) {
      setTimeout(() => tick(), 1000/ticksPerSecond)
    }
  }

  useEffect(() => {
      if (isTicking) {
      setTimeout(() => tick(), 1000/ticksPerSecond)
    }
  }, [isTicking])


  useEffect(() => {
    if (currentTick > totalTicks) {
      setIsTicking(false)
      setCurrentTick(0)
      triggerCallback()
    }
  }, [currentTick])

  useEffect(() => () => setIsTicking(false), [])

  function startTimer() {
    setIsTicking(true)
  }

  function stopTimer() {
    setIsTicking(false)
  }

  function resetTimer() {
    setCurrentTick(0)
  }

  const stats = `${currentTick} ${totalTicks}`
  return {startTimer, stopTimer, resetTimer, currentTick, totalTicks, stats}
}

const TIMER_SECONDS = 5

function DataFetcher() { 
  const TIMER_DELAY = 125
  const TIMER_INCREMENT = 1
  const [timer, setTimer] = useState(0)
  const [stopped, setStopped] = useState(false)
  const [timerLimit, setTimerLimit] = useState(700)
  const [timerSpeed, setTimerSpeed] = useState(20)
  const {startTimer, stopTimer, resetTimer, currentTick, totalTicks, stats} = useTimer(TIMER_SECONDS, triggerUpdate, 10)
  const dispatch = useDispatch()
  const {updateInProgress, automaticUpdates: auto, lastUpdateSuccessful} = useSelector(selectServerUpdatesStore)
  const {showLiveDevices} = useSelector(selectMapStore)

  useEffect(() => {
    if (auto) {
      triggerUpdate()
    }
  }, [])

  useEffect(() => {
    if (auto && ! updateInProgress) {
      startTimer()
    } else {
      stopTimer()
    }
  }, [auto, updateInProgress])

  function toggleAuto(auto) {
    if (! auto) {
      stopTimer()
      resetTimer()
    }
    dispatch(setAutoUpdate(auto))
  }

  function triggerUpdate() {
    dispatch(startUpdate())
  }

  let okNokColor = 'red'
  if (updateInProgress) {
    okNokColor = 'gray'
  } else if (lastUpdateSuccessful) {
    okNokColor = 'green'
  }
  
  let liveOrArchiveStyle = {
    padding: '1px 7px',
    borderRadius: '4px',
    border: '2px solid green',
    color: 'green',
  }

  if (!showLiveDevices) {
    liveOrArchiveStyle = {
      ...liveOrArchiveStyle,
      border: '2px solid red',
      color: 'red',
    }
  }

  function toggleToLive() {
    if (!showLiveDevices) {
      dispatch(showLiveDevicesAndAreas())
    }
  }

  return(
    <NaviTrapezoid color={WH_BG}>
      {moment().format("HH:mm:ss")}
      <Buttons.PlayPauseProgress 
        action={toggleAuto}
        timer={currentTick}
        timerLimit={totalTicks}
        style={NAV_B_STYLE}
        initialVal={auto}
      />
      <Buttons.Refresh action={() => dispatch(startUpdate())} disabled={auto || updateInProgress} style={NAV_B_STYLE} rotate={updateInProgress}/>
      <Buttons.OkNok style={NAV_B_STYLE} disabled={true} action={() => {}} color='red' superStyle={{color: okNokColor}}/>
      <button onClick={toggleToLive} style={liveOrArchiveStyle}>{showLiveDevices ? 'Live' : 'Archive'}</button>
      {/* <IndicatorLastTransmission val={'22:42:32'} style={styleLastTx} /> */}
      {/* <span style={{color: 'green', fontSize: '1.25em'}}>Live</span> */}
    </NaviTrapezoid>
  )
}

function CornerTriangle({color='white', size='20', right=false, left=false, up=false, down=false}) {
  const sizeRL = size / 2
  const sizeTB = size
  // const style = {
  //   width: 0,
  //   height: 0,
  //   borderTop: sizeTB + 'px solid ' + (up ? color : 'transparent'),
  //   borderBottom: sizeTB + 'px solid ' + (down ? color : 'transparent'),
  //   borderRight: sizeRL + 'px solid ' + (right ? color : 'transparent'),
  //   borderLeft: sizeRL + 'px solid ' + (left ? color : 'transparent'),
  // }
  const deg = right ? '105deg' : '285deg'
  const style = {
    width: size *3 + 'px',
    height: size *2 + 'px',
    background: `linear-gradient(${deg}, transparent 0%,  transparent 20%, ${color} 80%, ${color} 100%)`
  }

  return (
    <div className='nav-gradient-div' style={style}></div>
  )
}

function NaviTrapezoid({children, left=true, right=true, color='white'}) {
  return (
    <FlexBar className='shadow'>
      {left ? <CornerTriangle right={true} down={true} color={color}/> : null }
      <FlexBar background={color}>
        {children}
      </FlexBar>
      {right ? <CornerTriangle left={true} up={true} color={color}/> : null }
    </FlexBar>
  )
}

export function UserPanel({setDisplayDevPanel, toggleUserSettings}) {
  function toggleFullscreen() {
    const mainElement = document.getElementById('root')
    if (!document.fullscreenElement) {
      mainElement.requestFullscreen()
    } else {
      document.exitFullscreen()
    }
  }

  return (
    <div className='user-nav'>
      <NaviTrapezoid left={false} color={WH_BG}>
        <Buttons.FullScreen action={toggleFullscreen} style={NAV_B_STYLE} />
      </NaviTrapezoid>
      <DataFetcher />
      <UserBar toggleUserSettings={toggleUserSettings} setDisplayDevPanel={setDisplayDevPanel} />
    </div>
  )
}