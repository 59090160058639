import React, {useState} from 'react'

import {UserPanel} from './MainComponents/UserPanel'
import Trips from './MainComponents/Trips'
import {Devices} from './MainComponents/Devices'
import {UserSettings} from './MainComponents/UserSettings'
import {DeviceDetails} from './MainComponents/DeviceStatistics'
import {Areas} from './MainComponents/Areas'
import {CalendarDays} from './MainComponents/Calendar'
import {DevPanel} from './MainComponents/DevPanel'
import MapComponent from './Map/MapPage'
import {FlexBar} from './SmallComponents/flexBar'
import * as Buttons from './IconComponents/Buttons'
import {Toolbar} from './IconComponents/IconBar'

// REDUX
import { useSelector, useDispatch } from 'react-redux'
import {selectShowDeviceDetails} from './redux/reducers/uiReducer'
import {showLiveDevicesAndAreas} from './redux/actions/mapActions'

function manageHiding(show=[]) {
    const [isShown, direction] = show
    let sign = ''
    let axis = 'X'
    switch(direction) {
        case 'left':
            sign = '-'
            break
        case 'up':
            axis = 'Y'
            break
        case 'down':
            axis = 'Y'
            sign = '-'
            break
    }

    const mainStyle = {
        minHeight: 0,
        transition: 'all 1s ease',
    }

    if (isShown) {
        mainStyle['transform'] = `translate${axis}(0%)`
        // mainStyle['pointerEvents'] = 'none'
        // mainStyle['zIndex'] = '-10'
    } else {
        mainStyle['transform'] = `translate${axis}(${sign}100%)`
        // mainStyle['zIndex'] = '10'
    }

    return mainStyle
}

function Area({isBackground, isDevPanel=false, area, areaPosition, children, clsName, z=1, show, isMainComponent=false}) {
    let style = ''
    if (isBackground) {
        style = {
            gridColumn: '1 / -1',
            gridRow: '1 / -1',
            zIndex: '0',
        }
    } else if (isDevPanel) {
        style = {
            gridColumn: '1 / -1',
            gridRow: '2 / -1',
            zIndex: '0',
        }
    } else if (isMainComponent) {
        style = {
            gridColumn: '1 / -2',
            gridRow: '2 / -1',
            zIndex: '3',
            overflow: 'hidden',
        }
    } else {
        if (area) {
            style={
                gridArea: area,
                zIndex: z,
            }
        }
        if (areaPosition) {
            const column = areaPosition === 'right' ? '-2 / span 1' : '1 / span 1'
            style = {
                zIndex: z,
                gridRow: '2 / span 1',
                gridColumn: column,
            }
        }
    }

    if (show) {
        const hideStyle = manageHiding(show)
        style = {
            ...style,
            ...hideStyle,
        }
    }
    style = {
        ...style,
        maxHeight: '100vh',
        maxWidth: '100vw',
    }
    return(
        <div style={style} className={clsName}> 
            {children}
        </div>
    )
}


export default function MainPage() {
    const [showTrips, setShowTrips] = useState(false)
    const [showDevices, setShowDevices] = useState(false)
    const [showAreas, setShowAreas] = useState(false)
    const [showDevPanel, setShowDevPanel] = useState(false)
    const [showUserSettings, setShowUserSettings] = useState(false)

    const showDeviceDetails = useSelector(selectShowDeviceDetails)
    const dispatch = useDispatch()

    function toggleTrips() {
        setShowTrips(p => {
            if (!p) {
                setShowDevices(false)
                setShowAreas(false)
                setShowUserSettings(false)
            }
            return !p
        })
    }

    function toggleDevices() {
        setShowDevices(p => {
            if (!p) {
                setShowTrips(false)
                setShowAreas(false)
                setShowUserSettings(false)
            }
            return !p
        })
    }

    function toggleAreas() {
        setShowAreas(p => {
            if (!p) {
                setShowDevices(false)
                setShowTrips(false)
                setShowUserSettings(false)
            }
            return !p
        })
    }

    function toggleUserSettings() {
        setShowUserSettings(p => {
            if (!p) {
                setShowDevices(false)
                setShowTrips(false)
                setShowAreas(false)
            }
            return !p
        })
    }

    function hideAll() {
        setShowDevices(false)
        setShowTrips(false)
        setShowAreas(false)
        setShowDevPanel(false)
        setShowUserSettings(false)
    }

    return(
        <div className='main-page'>
            <Area area='nav' z='10'>
                <UserPanel
                    setDisplayDevPanel={setShowDevPanel}
                    toggleUserSettings={toggleUserSettings}
                />
            </Area>

            {/* {showDevPanel
                ?   <Area area='nav' z='0'>
                        <div style={{background: 'white', height: '100%'}}></div>
                    </Area>
                : null
            } */}
        
            
            <Area areaPosition='left' show={[showDevPanel, 'left']}>
                <DevPanel panelSelection={showDevPanel}/>
            </Area>

            {showDeviceDetails
                ?   <Area isMainComponent={true}>
                        <DeviceDetails id={showDeviceDetails} />
                    </Area>
                :   null
            }    
            <Area isBackground={true}>
                <MapComponent />
            </Area>        

            <Area areaPosition='right' show={[showTrips, 'right']}>
                <Trips />
            </Area>

            <Area areaPosition='right' show={[showDevices, 'right']}>
                <Devices />
            </Area>

            <Area areaPosition='right' show={[showAreas, 'right']}>
                <Areas />
            </Area>

            <Area areaPosition='right' show={[showUserSettings, 'right']}>
                <UserSettings />
            </Area>
            {/* <Area areaPosition='right' hide={[calendarHide, 'right']}>
                <Calendar />
            </Area> */}

            {/* Buttons positioned by absolute positioning */}
            <Buttons.Menu style={'circular-button button-center-bottom'}>
                {/* <Buttons.ButtonLab action={toggleLab} style={'circular-button'} /> */}
                <Buttons.ShowOnMap action={hideAll} style={'circular-button'} />
                <Buttons.SettingsUser action={toggleUserSettings} style={'circular-button'} />
                <Buttons.Trips action={toggleTrips} togglerMode={true} setActive={false} style={'circular-button'} />
                <Buttons.Areas action={toggleAreas} style={'circular-button'} />
                {/* <Buttons.ButtonMap action={toggleMap} style={'circular-button'} /> */}
                <Buttons.Device action={toggleDevices} style={'circular-button'} />
            </Buttons.Menu>
        </div>
    )
}