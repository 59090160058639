const PREFIX = '[ui]'

// Document type actions (reducer only)
export const SCROLL_TO_COMPONENT = `${PREFIX} SCROLL_TO_COMPONENT`
export const SHOW_COMPONENT_ON_MAP = `${PREFIX} SHOW_COMPONENT_ON_MAP`
export const HIDE_COMPONENT_ON_MAP = `${PREFIX} HIDE_COMPONENT_ON_MAP`
export const SHOW_DEVICE_DETAILS = `${PREFIX} SET_SHOWING_DEVICE_DETAILS`

/*
*
* Actions for specific components, based on base actions at the bottom.
*
*/

export const showDeviceDetails = (id) => ({
    type: SHOW_DEVICE_DETAILS,
    payload: id,
})

// Scroll to specific components on their lists
export const scrollToAreaById = (id) => (
    scrollToComponent('area', id)
)

export const scrollToDeviceById = (id) => (
    scrollToComponent('device', id)
)

// Scroll to a certain component from a list of components
export const scrollToComponent = (componentType, componentId) => ({
    type: SCROLL_TO_COMPONENT,
    payload: {
        componentType,
        componentId,
    }
})
