import React, {useState, useEffect} from 'react'
import {useSelector, useDispatch} from 'react-redux'
import useDeepCompareEffect from 'use-deep-compare-effect'
import { store } from 'react-notifications-component';

import {FlexBar, FlexCol} from '../SmallComponents/flexBar'
import * as Buttons from '../IconComponents/Buttons'
import * as DEVICE_COMMANDS from './deviceCommands'

// REDUX ACTIONS
import {
  createCommand,
  clearCommandLog,
} from '../redux/actions/devicesActions'
import {sendGeneralSettingsToServer} from '../redux/actions/userActions'
import {
  archiveDevicesErrors,
} from '../redux/actions/errorActions'

// REDUX SELECTORS
import {selectUserStore} from '../redux/reducers/userReducer'
import { Toolbar } from '../IconComponents/IconBar'
import {
  selectFilteredDevicesArray,
  selectDevicesStore,
} from '../redux/reducers/devicesReducer'


const FORM_FORMAT = {
    id: {hide: true},
    sms_sending_on: {type: 'checkbox'},
    sms_phone_number: {type: 'text'},
    area_audio_play_on: {type: 'checkbox'},
    reporting_interval: {type: 'number', min: 10, max: 3600},
    gps_reduction_radius: {type: 'number', min: 0, max: 1000},
}

function getEditOrDisplayField(edit=false, keyRaw, val, updateEditedField) {
    const format = FORM_FORMAT[keyRaw]
    const key = keyRaw.replace(/^\w/, (c) => c.toUpperCase()).replace(/[_-]/g, " "); 
    const fieldType = format.type
    if (format.hide) {
        return null
    }
    if (edit) {
        const inputStyle = {
            marginLeft: '5px'
        }

        if (fieldType === 'checkbox') {
            return (
                <label>{key}: 
                    <input
                        name={keyRaw}
                        style={inputStyle}
                        checked={val}
                        type={fieldType}
                        onChange={(event) => updateEditedField(event, fieldType)}
                    />
                </label>
            )
        } else if (fieldType === 'number') {
            return (
                <label>{key}: 
                    <input
                        name={keyRaw}
                        style={inputStyle}
                        value={val}
                        type={fieldType}
                        min={format.min}
                        max={format.max}
                        onChange={(event) => updateEditedField(event, fieldType)}/>
                </label>
            )
        } else {
            return (
                <label>{key}: 
                    <input
                        name={keyRaw}
                        style={inputStyle}
                        value={val}
                        type={fieldType}
                        onChange={(event) => updateEditedField(event, fieldType)}/>
                </label>
            )
        }
    } else {
        let value = val
        if (fieldType === 'checkbox') {
            value = val ? 'YES' : 'NO'
        }
        return <div>{key}: {value}</div>
    }
}

function FormattedFields({data, edit=false, close, save, updateEditedField}) {
    let settingsComps = Object.entries(data).map(([key, val]) => {
        return getEditOrDisplayField(edit, key, val, updateEditedField)
    })

    return (
        <FlexBar >
            <FlexCol style={{alignItems: 'flex-start'}}>
                {settingsComps}
            </FlexCol>
            {edit
                ?   <Toolbar>
                        <FlexCol>
                            <Buttons.Exit action={close} />
                            <Buttons.Save action={save} />
                        </FlexCol>
                    </Toolbar>
                :   null
            }
        </FlexBar>
    )
}

function ManagementCommands() {
  const dispatch = useDispatch()
  const devices = useSelector(selectFilteredDevicesArray)
  const {commandsRequestLog} = useSelector(selectDevicesStore)
  let numOfDevices = 0
  let devicesIdArray = []
  if (devices && devices.length > 0) {
      numOfDevices = devices.length
      devicesIdArray = devices.map(device => device.id)
  }

  function sendCommandToAll(actionName, cmd=null, altCommand=null) {
    dispatch(clearCommandLog())
    const confirm = window.confirm(`Confirm ${actionName} for ${numOfDevices}.`)
    console.log(devices)
    console.log(devicesIdArray)
    if (confirm) {
      for (const device of devicesIdArray) {
        if (altCommand) {
          altCommand(device)
        } else {
          dispatch(createCommand(device, cmd, 'token'))
        }
      }
    }
  }
  
  function confirmAction() {
    return window.confirm('Confirm?')
  }

  function restartAllActiveDevices() {
    if (confirmAction()) {
        sendCommandToAll('RESTART DEVICES', DEVICE_COMMANDS.RESTART_DEVICE)
    }
  }

  function archiveAllErrors() {
    const archiveFunc = id => dispatch(archiveDevicesErrors(id))
    if (confirmAction()) {
        sendCommandToAll('ARCHIVE ERRORS', null, archiveFunc)
    }
  }

  function restartServicesOnAllActiveDevices() {
    if (confirmAction()) {
      sendCommandToAll('RESTART SERVICES', DEVICE_COMMANDS.RESTART_ALL_SERVICES)
    }
  }

  function updateSoftwareOnAllActiveDevices() {
    if (confirmAction()) {
      sendCommandToAll('UPDATE FIRMWARE', DEVICE_COMMANDS.UPDATE_FIRMWARE)
    }
  }

  function shutdownAllActiveDevices() {
    if (confirmAction()) {
      sendCommandToAll('SHUTDOWN ALL DEVICES', DEVICE_COMMANDS.POWER_OFF_DEVICE)
    }
  }

  return (
    <>
        <FlexBar>
            <h3>Management commands:</h3>
        </FlexBar>
        <FlexBar>
          <div>
              <Toolbar>
                  <Buttons.FilterDevices />
              </Toolbar>
              <span style={{fontSize: '2em'}}>Devices: {numOfDevices}</span>
          </div>
          <div>
            Success: {commandsRequestLog.filter(i => i === true).length}
            <br/>
            Failed: {commandsRequestLog.filter(i => i === false).length}
          </div>
          <button onClick={() => dispatch(clearCommandLog())}>Clear</button>
        </FlexBar>
        <br/>
        <button onClick={restartAllActiveDevices}>Restart all active devices</button>
        <br/>
        <br/>
        <button onClick={restartServicesOnAllActiveDevices}>Restart services on all active devices</button>
        <br/>
        <br/>
        <button onClick={updateSoftwareOnAllActiveDevices}>Update firmware on active devices</button>
        <br/>
        <br/>
        <button onClick={shutdownAllActiveDevices}>Shutdown all active devices</button>
        <br/>
        <br/>
        <button onClick={archiveAllErrors}>Archive errors on active devices</button>
        <br/>
        <br/>
        <button onClick={archiveAllErrors}>Notification test</button>
        <br/>
        <br/>
    </>
  )
}

function GeneralSettings({data}) {
    const dispatch = useDispatch()
    const [edit, setEdit] = useState(false)
    const [fieldsEditedState, setFieldsEditedState] = useState({})
    
    useDeepCompareEffect(() => {
        setFieldsEditedState(data)
    }, [data, edit])

    function updateEditedField(event, fieldType) {
        const {name, type} = event.target
        let value = type === 'checkbox' ? event.target.checked : event.target.value
        if (fieldType === 'number') {
            value = parseInt(value)
        }
        setFieldsEditedState(p => ({...p, [name]: value}))
    }

    function save() {
        console.log(fieldsEditedState)
        dispatch(sendGeneralSettingsToServer(fieldsEditedState))
        setEdit(false)
    }

    return (
        <>
            <FlexBar>
                <h3>General settings:</h3>
                <Toolbar>
                    <Buttons.Rename action={() => setEdit(p => !p)} />
                </Toolbar>
            </FlexBar>
            <FormattedFields
                edit={false}
                data={data}
            />
            {edit
                ?   <>
                    <hr/>
                        <FormattedFields
                            edit={edit}
                            save={save}
                            data={fieldsEditedState}
                            close={() => setEdit(false)}
                            updateEditedField={updateEditedField}
                        />
                    </>
                : null
            }
        </>
    )
}


export function UserSettings() {
    const dispatch = useDispatch()
    const {name, isSuperUser, generalSettings} = useSelector(selectUserStore)
  
    const userIcon = isSuperUser ? 'fa-user-secret' : 'fa-user'

    return (
      <div className='big-toolbar-container'>
        <div className='device-title'>
            <FlexBar style={{paddingLeft: '7px'}}>
                <div style={{fontSize: '1.5em'}}>
                    <i className={"fas " + userIcon} style={{marginRight: '7px'}}></i>
                    {`${name} ${isSuperUser ? '[Administrator]' : ''}`}
                </div>
                
                <Toolbar>
                    <Buttons.Logout action={() => {}} rightText='Logout' />
                </Toolbar>
            </FlexBar>
        </div>
        <div className='scrollable-big-toolbar'>
            
            <div className='device-element'>
                <GeneralSettings data={generalSettings} />
            </div>
            <div className='device-element'>
                <ManagementCommands />
            </div>
        </div>
      </div>
    )
  }
  