import {SET_USER_NAME, UPDATE_GENERAL_SETTINGS} from '../actions/userActions'

export function userReducer(
    state={
        name: 'no-name',
        isLoggedIn: false,
        isSuperUser: false,
        generalSettings: {},
    }, action) {
    switch(action.type) {
        case SET_USER_NAME:
            let isSuperUser = false
            if (['Adam', 'Kamil'].includes(action.payload)) {
                isSuperUser = true
            }
            return {
                ...state, name: action.payload, isSuperUser
            }
        // case SET_LOGGED_IN:
        //     return {
        //         ...state, isLoggedIn: true
        //     }
        // case SET_LOGGED_OUT:
        //     return {
        //         ...state, isLoggedIn: false
        //     }
        case UPDATE_GENERAL_SETTINGS:
            const settingsArray = action.payload
            let settings = {}
            if (settingsArray && settingsArray.length > 0) {
                settings = settingsArray[0]
            }
            return {
                ...state,
                generalSettings: settings,
            }
        default:
            return state
    }
}

export const selectUserStore = state => state.user