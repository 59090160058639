import {
    UPDATE_TRIPS, 
} from '../actions/tripsActions'


export function tripsReducer(state={}, action) {
    switch(action.type) {
        case UPDATE_TRIPS:
            return action.payload
        default:
            return state
    }
} 

export const selectTripsStore = state => state.trips