import {
    SCROLL_TO_COMPONENT,
    SHOW_DEVICE_DETAILS,
} from '../actions/uiActions'

const initialState = {
    scrollTo: {},
    showDeviceDetails: null,
}

export function uiReducer(state=initialState, action) {
    switch(action.type) {
        case SCROLL_TO_COMPONENT:
            const {componentType, componentId} = action.payload
            if (state.scrollTo[componentType] === componentId) {
                return {
                    ...state,
                    scrollTo: {}
                }
            }
            return {
                ...state,
                scrollTo: {
                    [componentType]: componentId,
                }
            }
        case SHOW_DEVICE_DETAILS:
            const newDeviceForDetails = (state.showDeviceDetails === action.payload ? null : action.payload)
            return {
                ...state,
                showDeviceDetails: newDeviceForDetails,
            }
        default:
            return state
    }
}

export const selectUiStore = state => state.ui
export const selectShowDeviceDetails = state => selectUiStore(state).showDeviceDetails