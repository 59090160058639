const PREFIX = '[api]'

export const API_GET_REQUEST =              `${PREFIX} GET_REQUEST`
export const API_POST_REQUEST =             `${PREFIX} POST_REQUEST`
export const API_PUT_REQUEST =              `${PREFIX} PUT_REQUEST`
export const API_DELETE_REQUEST =           `${PREFIX} DELETE_REQUEST`
export const API_SAVE_REQUEST_DURATION =    `${PREFIX} SAVE_REQUEST_DURATION`

export const API_REQUEST_FAILED_ASK_USER_FOR_RETRY = `${PREFIX} FAILED_ASK_FOR_RETRY`

export const getRequest = (url, onSuccess, onError) => ({
    type: API_GET_REQUEST,
    payload: {url, onSuccess, onError}
})

export const postRequest = (url, params, onSuccess, onError) => ({
    type: API_POST_REQUEST,
    payload: {url, params, onSuccess, onError}
})

export const putRequest = (url, id, params, onSuccess, onError) => ({
    type: API_PUT_REQUEST,
    payload: {url, id, params, onSuccess, onError}
})

export const deleteRequest = (url, id, onSuccess, onError) => ({
    type: API_DELETE_REQUEST,
    payload: {url, id, onSuccess, onError}
})

export const saveRequestDuration = (startedTimestamp, endedTimestamp, url) => ({
    type: API_SAVE_REQUEST_DURATION,
    payload: [startedTimestamp, endedTimestamp, url]
})