import React from 'react'

export function Toolbar({children}) {
  return (
    <div className="ert">
      {children}
    </div>
  )
}

export function GridToolbar({children}) {
  return (
    <div className="grid-toolbar">
      {children}
    </div>
  )
}

export function InfoBar({children}) {
  return (
    <div className='icon-info-bar'>
      {children}
    </div>
  )
}


