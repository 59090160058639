const PREFIX = '[areas]'

// Command type actions (middleware only)
export const FETCH_AREAS = `${PREFIX} GET`
export const DELETE_AREA = `${PREFIX} DELETE_AREA`
export const CREATE_AREA = `${PREFIX} CREATE_AREA`
export const MODIFY_AREA = `${PREFIX} MODIFY_AREA`


// Document type actions (reducers only)
export const UPDATE_AREAS = `${PREFIX} UPDATE_AREAS`
export const UPDATE_VALUE = `${PREFIX} UPDATE_VALUE`
export const TOGGLE_VALUE = `${PREFIX} TOGGLE_VALUE`

// Errors
export const ERROR_FETCHING_AREAS = `${PREFIX} FETCHING_ERROR`
export const ERROR_CREATING_AREA = `${PREFIX} CREATING_ERROR`
export const ERROR_DELETING_AREA = `${PREFIX} DELETING_ERROR`
export const ERROR_MODIFYING_AREA = `${PREFIX} DELETING_ERROR`

export const toggleShowAreas = () => ({
    type: TOGGLE_VALUE,
    payload: 'showAreas',
})

export const toggleShowAreasLabels = () => ({
    type: TOGGLE_VALUE,
    payload: 'showAreasLabels',
})

export const getAreas = () => ({
    type: FETCH_AREAS,
})

export const deleteArea = (id) => ({
    type: DELETE_AREA,
    payload: id,
})

export const createArea = (params) => ({
    type: CREATE_AREA,
    payload: params
})

export const modifyArea = (id, params) => ({
    type: MODIFY_AREA,
    payload: {id, params}
})