import React from 'react'
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';
import {useSelector, useDispatch} from 'react-redux'

// REDUX
import {clearAppErrors} from '../redux/actions/errorActions'
import {selectLastReqDurationList} from '../redux/reducers/apiReducer'
import {selectErrorStore} from '../redux/reducers/errorReducer'

export function ErrorComponent({error_data}) {
    // const msg = error.error_msg.split('\n').map((item, i) => {
    //     return <p key={i}>{item}</p>;
    // });
    const error = error_data.error
    return (
        <div style={{border: '1px solid gray', margin: '0.5rem', padding: '0.5rem'}}>
            <p style={{maring: 0, fontSize: '0.8em'}}>{error_data.date.toString()}</p>
            <b>Source:</b> {error_data.source}
            <br/>
            <b>Name:</b> {error.name}
            <br/>
            <b>MSG:</b> {error.message}
            <br/>
            {/* MSG: {msg} */}
        </div>
    )
}

function ErrorsComponents({errors}) {
    const errorComponents = errors.recentErrors.map(error => <ErrorComponent key={error.id} error_data={error} />)

    return (
        <div className='error-panel'>
            {errorComponents}
        </div>
    )
}

function PerformancePanel() {
    const lastReqList = useSelector(selectLastReqDurationList)

    const comps = lastReqList.map(req => {
        return <div>Url: {req[0]} Duration: {req[1]}</div>
    })

    return (
        <div style={{background: 'white'}}>
            Performance panel
            {comps}
        </div>
    )
}

function ErrorPanel() {
    const {appErrors, serverErrors, devicesErrors} = useSelector(selectErrorStore)
    const dispatch = useDispatch()

    return (
        <div className='error-panel'>
            <Tabs>
                <TabList>
                <Tab>App Errors [{appErrors.numOfErrors}]</Tab>
                <Tab>Server Errors [{serverErrors.numOfErrors}]</Tab>
                <Tab>Devices Errors [{devicesErrors.numOfErrors}]</Tab>
                </TabList>

                <TabPanel>
                    <button onClick={() => dispatch(clearAppErrors())}>Clear app errors</button>
                    <ErrorsComponents errors={appErrors} />
                </TabPanel>
                <TabPanel>
                    <button onClick={() => dispatch(clearAppErrors())}>Clear server errors</button>
                    <ErrorsComponents errors={serverErrors} />
                </TabPanel>
                <TabPanel>
                    <h2>Any content 3</h2>
                </TabPanel>
            </Tabs>
        </div>
    )
}

export function DevPanel({panelSelection}) {

    return (
        <div className='big-toolbar-container'>

        {/* <div className='dev-panel'> */}
            <div className='trip-title'>
                DEV PANEL: {String(panelSelection).toUpperCase()}
            </div>

            <div className='scrollable-big-toolbar'>
                {panelSelection == 'errors'
                    ? <ErrorPanel />
                    : null
                }
                {panelSelection == 'performance'
                    ? <PerformancePanel />
                    : null
                }
            </div>
        </div>
    )
}