import {UPDATE_AREAS, UPDATE_VALUE, TOGGLE_VALUE} from '../actions/areasActions'

const initialState = {
    areas: [],
    showAreas: true,
    showAreasLabels: false,
}

export function areasReducer(state=initialState, action) {
    switch(action.type) {
        case UPDATE_AREAS:
            return {
                ...state,
                areas: action.payload,
            }
        case UPDATE_VALUE:
            return {
                ...state,
                [action.payload.name]: action.payload.value,
            }
        case TOGGLE_VALUE:
            return {
                ...state,
                [action.payload]: !state[action.payload],
            }
        default:
            return state
    }
}

export const selectAreasStore = state => state.areas
export const selectAreas = state => selectAreasStore(state).areas