import {
    FETCH_GPS_POSITIONS, 
    CREATE_GPS_POSITION,
    UPDATE_GPS_POSITIONS,
} from '../actions/gpsPositionsActions'
import {
    getRequest,
    postRequest,
    deleteRequest,
    putRequest,
} from '../actions/apiActions'

const GPS_POSITIONS_URL = 'gps_measurements'

const getGpsPositionsProcess = ({dispatch}) => next => action => {
    next(action)
    if (action.type === FETCH_GPS_POSITIONS) {
        dispatch(getRequest(GPS_POSITIONS_URL, UPDATE_GPS_POSITIONS, null))
    }
}

const createGpsPositionProcess = ({dispatch}) => next => action => {
    next(action)
    if (action.type === CREATE_GPS_POSITION) {
        dispatch(postRequest(GPS_POSITIONS_URL, action.payload, FETCH_GPS_POSITIONS, null))
    }
}

export const gpsPositionsMiddleware = [
    getGpsPositionsProcess,
    createGpsPositionProcess,
]