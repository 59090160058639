import React, {useState} from 'react'
import moment from 'moment'

import {useSelector, useDispatch} from 'react-redux'
import {deleteTrip, modifyTrip, createTrip} from '../redux/actions/tripsActions'
import {usePinning} from '../customHooks/usePinning'
import {Toolbar} from '../IconComponents/IconBar'
import {FlexBar, FlexCol} from '../SmallComponents/flexBar'
import {DevicesBatch} from './Devices'
import * as Buttons from '../IconComponents/Buttons'

import './style.css'

// REDUX
import {
    filterInactiveDevices,
    toggleShowGpsTrails,
} from '../redux/actions/devicesActions'
import {
    selectDevicesArray,
    selectFilteredDevicesArray,
    selectFilterInactiveDevices,
    selectShowGpsTrails,
} from '../redux/reducers/devicesReducer'
import {selectTripsStore} from '../redux/reducers/tripsReducer'

export function TimeDisplay({data}) {
    let element = '-'

    if (data) {
        const day = data && data.format('DD')
        const monthYear = data && data.format('-MM-YYYY')
        const time = data && data.format('HH:mm')
        element = (
            <FlexBar>
                <FlexBar>
                    <p style={{margin: '0 3px 0 0', fontSize: '2.125em', color: '#454545'}}>{day}</p>
                    <FlexCol>
                        <p style={{margin: 0, fontSize: '0.875em'}}>{monthYear}</p>
                        <p style={{margin: '-8px 0 0 0', fontSize: '1.125em', color: '#'}}>{time}</p>
                    </FlexCol>
                </FlexBar>
                <div></div>
            </FlexBar>
        )
    } else {
        element = <div style={{fontSize: '3.5em'}}>-</div>
    }

    return element
}

export function Trip({trip, Pin, devices, forceExpanded, isUnlocked}) {
    const dispatch = useDispatch()
    const [modifyTrip, setModifyTrip] = useState(false)
    const selectedDevicesIds = trip.devices
    const selectedDevices = devices.filter(device => selectedDevicesIds.includes(device.id))

    function callRenameTrip() {
        const newName = window.prompt('Provide new name:')
        if (newName) {
            dispatch(modifyTrip(trip.id, newName))
        }
    }

    function callDeleteTrip() {
        let confirmed = window.confirm('Confirm deleting trip.')
        if (confirmed) {
            dispatch(deleteTrip(trip.id))
        }
    }

    const [isExpanded, setIsExpanded] = useState(false)
    const numOfDevices = devices.length

    const toolbar = (
        <>
            <Buttons.Expand
                action={setIsExpanded}
                val={numOfDevices}
                force={forceExpanded}
            />
            <Buttons.Rename action={() => setModifyTrip(p => !p)} />
            {isUnlocked
                ? <Buttons.Delete action={callDeleteTrip} />
                : null
            }
            {Pin}
        </>
    )

    const startDateTime = trip && trip.start_date && moment(trip.start_date).local()
    
    const endDateTime = trip && trip.end_date && moment(trip.end_date).local()

    return(
        <div className='trip-element' style={{paddingLeft: '7px'}}>
            <FlexBar>
                <div>
                    <h3>Name: {trip.name}</h3>
                    <h3>Devices: {selectedDevicesIds.length}</h3>
                </div>
                <Toolbar>
                    {toolbar}
                </Toolbar>
            </FlexBar>
            <FlexBar>
                <FlexBar>
                    <TimeDisplay data={startDateTime} />
                    <i style={{margin: '0 1em'}} className="fas fa-angle-double-right"></i>
                    <TimeDisplay data={endDateTime} />
                </FlexBar>
                <div>
                    <div>Last known position</div>
                    <div>Last contact</div>
                </div>
            </FlexBar>
                
            
            {modifyTrip
                ? <ModifyTrip currentData={trip} close={() => setModifyTrip(false)} />
                : null
            }
            {isExpanded && !modifyTrip
                ?   <div>
                        <hr/>
                        <DevicesBatch devices={selectedDevices} />
                    </div>
                : null
            }
        </div>
    )
}

function ModifyTrip({currentData, close}) {
    const dispatch = useDispatch()
    const devices = useSelector(selectFilteredDevicesArray)
    const [name, setName] = useState(currentData ? currentData.name : '')
    
    const initialStartDateTime = currentData ? moment(currentData.start_date).local() : moment()
    const initialEndDateTime = currentData ? moment(currentData.end_date).local() : moment()

    const [startDateTime, setStartDateTime] = useState(initialStartDateTime)
    const [endDateTime, setEndDateTime] = useState(initialEndDateTime)

    const [selectedDevices, setSelectedDevices] = useState(currentData ? currentData.devices : [])

    function saveTrip() {
        // const startDateTimeUtc = moment.utc(startDateTime)
        // const endDateTime = moment.utc(endDateTime)
        // console.log('SHOULD BE UTC')
        // console.log(startDateTimeUtc)
        if (currentData) {
            dispatch(modifyTrip(currentData.id, name, startDateTime, endDateTime, selectedDevices))
        } else {
            dispatch(createTrip(name, startDateTime, endDateTime, selectedDevices))
        }
        close()
    }

    function inputDateTimeChange(event) {
        const {name, value} = event.target
        switch(name) {
            case 'startDate':
                setStartDateTime(p => {
                    const newDate = moment(`${value} ${p.format('HH:mm')}`)
                    return newDate.isValid() ? newDate : p
                })
                break
            case 'startTime':
                setStartDateTime(p => {
                    const newDate = moment(`${p.format('YYYY-MM-DD')} ${value}`)
                    return newDate.isValid() ? newDate : p
                })
                break
            case 'endDate':
                setEndDateTime(p => {
                    const newDate = moment(`${value} ${p.format('HH:mm')}`)
                    return newDate.isValid() ? newDate : p
                })
                break
            case 'endTime':
                setEndDateTime(p => {
                    const newDate = moment(`${p.format('YYYY-MM-DD')} ${value}`)
                    return newDate.isValid() ? newDate : p
                })
                break
        }
    }

    const startDate = startDateTime.format('YYYY-MM-DD')
    const startTime = startDateTime.format('HH:mm')
    const endDate = endDateTime && endDateTime.format('YYYY-MM-DD')
    const endTime = endDateTime && endDateTime.format('HH:mm')

    return (
        <div style={{padding: '0 7px', background: 'white'}}>
            <hr/>
            <FlexBar>
                <FlexCol style={{alignItems: 'flex-start'}}>
                    <h3>{currentData ? 'Modify trip' : 'Create new trip'}</h3>
                    <label>
                        Name: <input value={name} onChange={event => setName(event.target.value)} type='text' />
                    </label>
                    
                    <label style={{margin: '5px 0'}}>
                        Start date: 
                        <input name='startDate' value={startDate} onChange={inputDateTimeChange} type='date' />
                        <input name='startTime' value={startTime} onChange={inputDateTimeChange} type='time' />
                    </label>
                    <br/>
                    <label>
                        End date: 
                        <input name='endDate' value={endDate} onChange={inputDateTimeChange} type='date' />
                        <input name='endTime' value={endTime} onChange={inputDateTimeChange} type='time' />
                    </label>
                </FlexCol>
                <FlexCol>
                    <Toolbar>
                        <Buttons.Exit action={close} />
                    </Toolbar>
                    <Toolbar>
                        <Buttons.Save action={saveTrip} />
                    </Toolbar>
                </FlexCol>
            </FlexBar>
            {/* <div> */}
                <DevicesBatch
                    devices={devices}
                    noButtons={true}
                    multiSelect={true}
                    selectedDevices={selectedDevices}
                    setSelectedDevices={setSelectedDevices}
                />
            {/* </div> */}
        </div>
    )
}

export default function Trips() {
    /* Use either this component containing Device components
    or Devices component instead of Trips */

    const dispatch = useDispatch()

    const [isUnlocked, setIsUnlocked] = useState(false)
    const [areExpanded, setAreExpanded] = useState(false)
    const [creatingNewTrip, setCreatingNewTrip] = useState(false)

    const devices = useSelector(selectFilteredDevicesArray)
    const numOfDevices = devices.length
    const filterInactiveDevicesData = useSelector(selectFilterInactiveDevices)
    const shouldShowGpsTrails = useSelector(selectShowGpsTrails)

    const tripsState = useSelector(selectTripsStore)
    const trips = Object.values(tripsState)
    const numOfTrips = trips.length
    const [sortByPins, getPin] = usePinning()
    const tripsSorted = sortByPins(trips)

    const TripComponents = tripsSorted.map(trip => {
        return (
            <Trip
                key={trip.id}
                trip={trip}
                Pin={getPin(trip.id)}
                devices={devices}
                forceExpanded={areExpanded}
                isUnlocked={isUnlocked}
            />
        )
    })

    function filterByActivity() {
        dispatch(filterInactiveDevices())
    }

    const toolbar = (
        <>
            <Buttons.FilterDevices />
            <Buttons.Trips
              action={() => dispatch(toggleShowGpsTrails())}
              togglerMode={true}
              setActive={shouldShowGpsTrails}
            />
            <Buttons.Expand action={setAreExpanded} val={numOfDevices}/>
            <Buttons.Create action={() => setCreatingNewTrip(p => !p)}/>
            <Buttons.Lock action={setIsUnlocked} />
        </>
    )

    return (
        <div className='big-toolbar-container'>
            <div className='trip-title'>
                <div>
                    <FlexBar>
                        <h2>All trips: {numOfTrips || 'sync to view'}</h2>
                        <Toolbar>
                            {toolbar}
                        </Toolbar>      
                    </FlexBar>
                </div>
            </div>
            <div className='scrollable-big-toolbar'>
                {creatingNewTrip
                    ? <ModifyTrip close={() => setCreatingNewTrip(false)} />
                    : null
                }
                {TripComponents}
            </div>
         </div>
    )
}