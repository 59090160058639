import React, {useState, useEffect, useRef} from 'react'
import {useSelector, useDispatch} from 'react-redux'

import { ReactTabulator } from 'react-tabulator'
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import 'react-tabulator/lib/styles.css'
import "react-tabulator/css/tabulator.min.css" // use Theme(s)

import {FlexBar, FlexCol} from '../SmallComponents/flexBar'
import * as Buttons from '../IconComponents/Buttons'
import {Toolbar, InfoBar} from '../IconComponents/IconBar'
import {ReportsArchive} from './ReportsArchive'
import {
  IndicatorBattery,
} from '../IconComponents/Indicators'

// REDUX
import {focusOnDeviceOnMap, scrollToDeviceById, trackDeviceOnMap, showDeviceDetails} from '../redux/actions/uiActions'
import {
  selectDeviceById,
  selectCommandsById,
  selectDevicesArray,
  selectFilteredDevicesArray,
  selectFilterInactiveDevices,
  selectHiddenDevices,
  selectShowGpsTrails,
} from '../redux/reducers/devicesReducer'
import {
  createCommand,
  filterInactiveDevices,
  toggleShowGpsTrails,
} from '../redux/actions/devicesActions'
import {DeviceErrorsArchive} from './DeviceErrorsArchive';

export class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }
  static getDerivedStateFromError(error) {    // Update state so the next render will show the fallback UI.
    return { hasError: true };  
  }
  componentDidCatch(error, errorInfo) {    // You can also log the error to an error reporting service
    // logErrorToMyService(error, errorInfo);
  }
  render() {
    if (this.state.hasError) {      // You can render any custom fallback UI
      return <h3>Tabulator error. Stupid tabulator.</h3>;
    }
    return this.props.children; 
  }
}

export function CommandsTable({data}) {
// const data = [
//   { id: 1, name: 'Oli Bob', age: '12', color: 'red', dob: '01/01/1980', rating: 5, passed: true, pets: ['cat', 'dog'] },
//   { id: 2, name: 'Mary May', age: '1', color: 'green', dob: '12/05/1989', rating: 4, passed: true, pets: ['cat'] },
// ];
const tableRef = useRef(null)
const [tableComp, setTableComp] = useState(null)
const columns = [
  { title: "ID", field: "id", width: 50},
  { title: "Cmd", field: "cmd", formatter: 'textarea'},
  { title: "Created", field: "created"},
  { title: "Expiration date", field: "expiration_date", formatter: 'textarea'},
  { title: "Received", field: "received", width: 100, formatter: "tick", hozAlign: "center",},
  { title: "Received date", field: "received_date", formatter: 'textarea'},
  { title: "Executed", field: "executed", width: 100, formatter: "tick", hozAlign: "center",},
  { title: "Result", field: "result", formatter: 'textarea', widthGrow: 4},
]

useEffect(() => {
  // if (tableRef.current && tableRef.current.replaceData) {
  //   tableRef.current.replaceData(data)
  if (tableComp && tableComp.replaceData) {
    tableComp.replaceData(data)
  } else if (data.length > 0) {
    setTableComp(
        <ErrorBoundary>
        <ReactTabulator
            minRows={0}
            ref={tableRef}
            columns={columns}
            data={data}
            tooltips={true}
            layout={"fitDataFill"}
            layoutColumnsOnNewData={false}
        />
        </ErrorBoundary>
    )
    }
  }, [data])

  return (
      <div>
      {tableComp}
      </div>
  )
}

// Obj key holding process name
const PROCESS_NAME = '_process_name'

export const CMD_UPDATE_REPO = "./root/kayak-reporting/update_repo.sh"
export const CMD_RESTART_DEVICE = "reboot"
export const CMD_POWER_OFF_DEVICE = "poweroff"

export function DeviceDetails({id}) {
  const device = useSelector(selectDeviceById(id))
  const commandsForDevice = useSelector(selectCommandsById(id))
  const dispatch = useDispatch()
  const [cmd, setCmd] = useState(null)
  const [phoneNumber, setPhoneNumber] = useState('')
  const [smsMessage, setSmsMessage] = useState('')


  function sendCommand() {
    if (cmd) {
      dispatch(createCommand(id, cmd, 'token'))
    }
  }

  function sendSMSCommand() {
    if (phoneNumber !== '' && smsMessage !== '') {
      const command = `./root/kayak-reporting/sms.py ${phoneNumber} "${smsMessage}"`
      dispatch(createCommand(id, command, 'token'))
    }
  }

  function handleChange(event) {
    const {name, value} = event.target
    setCmd(value)
  }

  const commandsComp = (
    <div style={{border: '1px solid lightgray', padding: '5px'}}>
      <label>Send command:
        <select style={{margin: '0 5px'}} onChange={handleChange}>
          <option value={null}>Select...</option>
          <option value="nmcli c modify Gordo wifi-sec.key-mgmt wpa-psk wifi-sec.psk Zielonytaras2">Change WIFI password</option>
          <option value="python3 /root/kayak-reporting/play_percent.py">Check sound</option>
          <option value="python3 /root/kayak-reporting/play_percent.py">Play music</option>
          <option value="./root/kayak-reporting/identify.py">Device identification</option>
          <option value="pwd">CMD: pwd</option>
          <option value="./root/kayak-reporting/scripts/systemd_restart_services.sh">Restart all services</option>
          <option value="./root/kayak-reporting/scripts/systemd_status_services.sh">Check services statuses</option>
          <option value="hostname">CMD: hostname</option>
          <option value={CMD_UPDATE_REPO}>Update repository</option>
          <option value="nmcli c up Gordo">WiFi ON</option>
          <option value="nmcli c down Gordo">WiFi OFF</option>
          <option disabled={true} value="./root/kayak-reporting/bash_scripts/modem_on.sh">Modem ON</option>
          <option disabled={true} value="./root/kayak-reporting/bash_scripts/modem_off.sh">Modem OFF</option>
          <option value="poweroff">CMD: poweroff</option>2
        </select>
      </label>
      {`CMD: ${cmd}`}
      <button style={{marginLeft: '5px'}} onClick={sendCommand}>Send</button>
  </div>
  )

  return (
    // <div className='big-toolbar-container'>
    //    {/* <Buttons.ButtonExit action={() => dispatch(showDeviceDetails(id))} /> */}
      <Tabs className='big-toolbar-container' style={{flexGrow: '3', marginRight: '7px'}}>
        <div className='device-title'>
          <FlexBar>
            <h2>Device name: {device.name}</h2>
            <Toolbar>
              <Buttons.Exit action={() => dispatch(showDeviceDetails(null))} />
            </Toolbar>
          </FlexBar>
          <TabList>
            <Tab>Commands</Tab>
            <Tab>Reports</Tab>
            <Tab>Errors</Tab>
            <Tab>Battery statistics</Tab>
          </TabList>
        </div>
        <div className='scrollable-big-toolbar'>
          <TabPanel style={{background: 'white'}}>
              {commandsComp}
              <div style={{border: '1px solid lightgray', padding: '5px'}}>
                SMS:
                <input type='text' value={phoneNumber} onChange={(event) => setPhoneNumber(event.target.value)} placeholder='phone number' style={{margin: '0 5px'}}/>
                <input type='text' value={smsMessage} onChange={(event) => setSmsMessage(event.target.value)} placeholder='message' />
                <button style={{marginLeft: '5px'}} onClick={sendSMSCommand}>Send</button>
              </div>
            <hr/>
              <CommandsTable data={commandsForDevice}/>
          </TabPanel>
          <TabPanel>
            <ReportsArchive deviceId={device.id} />
          </TabPanel>
          <TabPanel>
            <DeviceErrorsArchive deviceId={device.id} />
          </TabPanel>
          <TabPanel>
            Battery statistics
          </TabPanel>
          </div>
      </Tabs>
    // </div>
  )
}



export function getErrorsFromOneSection(section) {
  const errors = section['_errors']
  let errorList = []
  let errorsPresent = true
  if (errors === undefined || errors.length == 0) {
    errorsPresent = false
  } else {
    errorList = errors
  }
  return [errorsPresent, errorList]
}

export function getErrorsFromAllSections(sections) {
  const sectionArray = Object.values(sections).map(item => [item[PROCESS_NAME], getErrorsFromOneSection(item)])
  const errorArray = sectionArray.filter(errorData => errorData[1][0]).map(item => [item[0], item[1][1]])
  return errorArray
}

export function DeviceStatistics({deviceStatsObj, noLine=false}) {
  function parseSection(item) {
    const data = item['data']
    
    let section = ''
    let errorComp = null
    const [errorsPresent, errors] = getErrorsFromOneSection(item)
    if (errorsPresent) {
      errorComp = errors.map(error => {
        return <p key={error.id} style={{margin: '0', color: 'red'}}>{error}</p>
      })
    }

    section = Object.values(data).map(value => {
      const prop_value = value[0]
      const isNull = prop_value === 'null'
      const style = {margin: '0', fontSize: '0.8em'}
      if (isNull) {
        style['color'] = 'gray'
      }
      return <p style={style}>{value[1]} {isNull ? '-' : prop_value}{isNull ? '' : value[2]}</p>
    })

    return (
      <div>
        {noLine ? null: <hr/>}
        <h4>{item[PROCESS_NAME]}</h4>
        {errorComp}
        {section}
      </div>
    )
  }

  let comps = null
  if (deviceStatsObj) {
    comps = Object.values(deviceStatsObj).map(item => parseSection(item))
  }

  return (
    <>
      {comps}
    </>
  )
}

export function BatteryIndicatior({batteryPercent, batteryTime}) {
  let color = 'green'
  if (batteryPercent < 20) {
    color = 'red'
  } else if (batteryPercent < 40) {
    color = 'orange'
  }
  return (
    <div style={{fontSize: '0.85em', borderBottom:'1px solid #d6d6d6', marginBottom: '-5px'}}>
      <FlexBar>
        <IndicatorBattery val={batteryPercent} />
        <div>{batteryPercent}%</div>
        <div>Time: {batteryTime}</div>
      </FlexBar>
      <div style={{background: color, height: '7px', width: `${batteryPercent}%`, marginTop:'-2px'}}></div>
    </div>
  )
}