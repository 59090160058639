import {API_SAVE_REQUEST_DURATION} from '../actions/apiActions'


const initialState = {
    lastReqDuration: 0,
    last50req: [],
    last40reqList: [],
}

export function apiReducer(state=initialState, action) {
    switch(action.type) {
        case API_SAVE_REQUEST_DURATION:
            const [startTime, endTime, url] = action.payload
            const duration = endTime - startTime
            const new50req = [duration, ...state.last50req]
            if (new50req.length > 50) {
                new50req.pop()
            }
            const new40reqList = [[url, duration], ...state.last40reqList]
            if (new40reqList.length > 50) {
                new40reqList.pop()
            }
            return {
                ...state,
                lastReqDuration: duration,
                last50req: new50req,
                last40reqList: new40reqList,
            }
        default:
            return state
    }
}

export const selectApiStore = state => state.apiStats
export const selectLastReqDurationList = state => selectApiStore(state).last40reqList