import {
    UPDATE_REPORTS_DATA,
    SET_UPDATE_IN_PROGRESS,
    SERVER_COM_FAILED,
} from '../actions/reportsArchiveActions'

const initialState = {
    updateInProgress: false,
    reportsData: {},
    /* inside reportsData:
        {
            _device_id: {days}
        }
    */
}

export function reportsArchiveReducer(state=initialState, action) {
    switch(action.type) {
        case UPDATE_REPORTS_DATA:
            const {device_id, range_data, day, day_data} = action.payload
            let newReportsData = null
            if (day) {
                const thisDevice = state.reportsData[device_id]
                const newData = {...thisDevice, [day]: day_data}
                newReportsData = {...state.reportsData, [device_id]: newData}
            } else {
                newReportsData = {...state.reportsData, [device_id]: range_data}
            }
            return {
                ...state,
                updateInProgress: false,
                reportsData: newReportsData,
            }

        case SET_UPDATE_IN_PROGRESS:
            return {
                ...state,
                updateInProgress: true,
            }
        case SERVER_COM_FAILED:
            return {
                ...state,
                updateInProgress: false,
            }
        default:
            return state
    }
}

export const selectReportsArchiveStore = state => state.reportsArchive
export const selectReportsData = state => selectReportsArchiveStore(state).reportsData
export const selectReportsDataById = id => state => selectReportsData(state)[id]