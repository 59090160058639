const PREFIX = '[gps positions]'

// Command type actions (middleware only)
export const FETCH_GPS_POSITIONS = `${PREFIX} GET`
export const CREATE_GPS_POSITION = `${PREFIX} CREATE_GPS_POSITION`


// Document type actions (reducers only)
export const UPDATE_GPS_POSITIONS = `${PREFIX} UPDATE_GPS_POSITIONS`

// Errors

export const getGpsPositions = () => ({
    type: FETCH_GPS_POSITIONS,
})

export const createGpsPosition = (sensorNumber, lat, lng) => {
    const currentDate = new Date()
    return {
        type: CREATE_GPS_POSITION,
        payload: {
            latitude: lat,
            longitude: lng,
            sensor: sensorNumber,
            date: currentDate.toISOString().substring(0,22),
        }
    }
}