import {
    SET_GPS_FILTERING,
    SET_DEVICES_FILTERING,
    SET_AUTO_UPDATE,
    SET_UPDATE_RESULT,
    SET_UPDATE_IN_PROGRESS,
    ERROR_GETTING_UPDATE,
} from '../actions/serverUpdatesActions'

const initialState = {
    automaticUpdates: true,
    updateInProgress: false,
    lastUpdateSuccessful: true,
    lastUpdateDate: null,
    lastUpdateError: null,

    gpsFilteringValue: 10,

    devicesFilteringMode: null,
    devicesFilteringList: null,
}

export function serverUpdatesReducer(state=initialState, action) {
    switch(action.type) {
        case SET_GPS_FILTERING:
            return {
                ...state,
                gpsFilteringValue: action.payload.value,
            }
        case SET_DEVICES_FILTERING:
            return {
                ...state,
                devicesFilteringMode: action.payload.mode,
                devicesFilteringList: action.payload.list,
            }
        
        case SET_AUTO_UPDATE:
            return {
                ...state,
                automaticUpdates: action.payload,
            }
        case SET_UPDATE_RESULT:
            return {
                ...state,
                ...action.payload,
            }
        case SET_UPDATE_IN_PROGRESS:
            return {
                ...state,
                updateInProgress: true,
            }
        case ERROR_GETTING_UPDATE:
            return {
                ...state,
                updateInProgress: false,
                lastUpdateSuccessful: false,
                lastUpdateError: action.payload,
            }
        default:
            return state
    }
}

export const selectServerUpdatesStore = state => state.serverUpdates
