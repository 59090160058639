import {createStore} from "redux"

import {reducers} from './reducers'
import {middlewares} from './middleware'

const store = createStore(
    reducers,
    middlewares,
    // window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__(),
)
// store.subscribe(() => {
//     console.log(store.getState())
// })

export default store