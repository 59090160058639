const PREFIX = '[trips]'

// Command type actions (middleware only)
export const FETCH_TRIPS = `${PREFIX} GET`
export const DELETE_TRIP = `${PREFIX} DELETE_TRIP`
export const CREATE_TRIP = `${PREFIX} CREATE_TRIP`
export const MODIFY_TRIP = `${PREFIX} MODIFY_TRIP`


// Document type actions (reducers only)
export const UPDATE_TRIPS = `${PREFIX} UPDATE_TRIPS`

// Errors
export const ERROR_FETCHING_TRIPS = `${PREFIX} FETCHING_ERROR`
export const ERROR_CREATING_TRIP = `${PREFIX} CREATING_ERROR`
export const ERROR_DELETING_TRIP = `${PREFIX} DELETING_ERROR`
export const ERROR_RENAMING_TRIP = `${PREFIX} RENAMING_ERROR`

export const getTrips = () => ({
    type: FETCH_TRIPS,
})

export const deleteTrip = (id) => ({
    type: DELETE_TRIP,
    payload: id,
})

export const createTrip = (name, startDate, endDate, devices) => ({
    type: CREATE_TRIP,
    payload: {
        name,
        start_date: startDate,
        end_date: endDate,
        devices,
    }
})

export const modifyTrip = (id, name, startDate, endDate, devices) => ({
    type: MODIFY_TRIP,
    payload: {id,
        name,
        start_date: startDate,
        end_date: endDate,
        devices,
    }
})