import React, {useState, useRef, useEffect} from 'react'
import {Link, Switch, Route} from "react-router-dom"
import {useHistory} from 'react-router'
import {useDispatch} from 'react-redux'
import * as Sentry from "@sentry/react";
import { Integrations } from "@sentry/tracing";
import ReactNotification from 'react-notifications-component'
import 'react-notifications-component/dist/theme.css'

import 'react-calendar/dist/Calendar.css';

import './App.css'
import logo from './static/kayak.png'

import MainPage from './MainPage'
import Testing from './Testing'
import {setUserName} from './redux/actions/userActions'


function Login(props) {
  const [passwd, setPasswd] = useState('password')
  const history = useHistory()
  const passwdRef = useRef(null)
  const nameRef = useRef(null)
  const style={
    display: 'flex',
    flexDirection: 'column',
  }
  const dispatch = useDispatch()

  const x={
    borderRadius: '0.35rem',
    border: '1px solid gray',
    margin: '0.5rem',
    padding: '0.5rem',
    height: '2.25rem',
    textAlign: 'center',
  }

  function login(event) {
    event.preventDefault()
    // alert(passwdRef.current.value)
    // () => props.login(p => !p)
    if (passwdRef.current.value === 'test') {
      history.push('/main')
      dispatch(setUserName(nameRef.current.value))
    } else (
      alert('Wrong password!')
    )
  }

  useEffect(() => {
    nameRef.current.focus()
  }, [])

  return (
      <form onSubmit={login} style={style}>
        <input style={x} type='text' placeholder='login' ref={nameRef} />
        <input style={x}
          type='password'
          placeholder='password'
          ref={passwdRef}
          />
        <button style={x} type='submit'>Log In</button>
      </form>
  )
}

class Logo extends React.Component {
  render() {
    return (
      <div className='logo-area'>
          <img src={logo} className={`App-logo`} alt="logo" />
      </div>
    )
  }
}

function LoginPage(props) {
  const [isLoggedIn, setIsLoggedIn] = useState(false)

  return (
    <div className="login-page">
        <Logo />

        {/* <Fade down when={!isLoggedIn} collapse> */}
          <Login login={setIsLoggedIn} isLoggedIn={isLoggedIn}/>
        {/* </Fade> */}

        {/* <Fade up when={isLoggedIn} collapse>
            <div> 
              <MenuPage isLoggedIn={isLoggedIn}/>
              <button onClick={() => setIsLoggedIn(p => !p)}>Log out</button>
            </div>
        </Fade> */}
        
    </div>
  );
}

// class ErrorBoundary extends React.Component {
//   constructor(props) {
//     super(props);
//     this.state = { hasError: false };
//   }

//   componentDidCatch(error, info) {
//     // Display fallback UI
//     this.setState({ hasError: true });
//     // You can also log the error to an error reporting service
//     // logErrorToMyService(error, info);
//     reportError()
//   }

//   render() {
//     return this.props.children;
//   }
// }

//   - [x] Add saving error types and instances to DB in error_counter / handler

function App() {
  return(
      <div id='main-page-id' className='app-main-page'>
        <ReactNotification />
        <Switch>
          <Route exact path='/'><LoginPage /></Route>
          {/* <Route path='/devices'><DevicesPage /></Route> */}
          <Route path='/login'><Login /></Route>
          <Route path='/testing'><Testing /></Route>
          <Route path='/main'><MainPage /></Route>
        </Switch>
      </div>
  )
}

export default App;
