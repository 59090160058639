import {
    FETCH_DEVICES_ERRORS,
    FETCH_DEVICES_ERROR_TYPES,
    UPDATE_DEVICES_ERRORS,
    SERVER_COM_FAILED,
    ARCHIVE_DEVICE_ERRORS,
} from '../actions/errorActions'
import {
    getRequest,
    postRequest,
    deleteRequest,
    putRequest,
    API_REQUEST_FAILED_ASK_USER_FOR_RETRY,
} from '../actions/apiActions'

const HANDLE_ERRORS_URL = 'handle_device_errors'

const getErrorsProcess = ({dispatch}) => next => action => {
    next(action)
    if (action.type === FETCH_DEVICES_ERRORS) {
        const params = {
            action: 'get_for_device',
            device: action.payload,                   
        }
        dispatch(postRequest(HANDLE_ERRORS_URL, params, UPDATE_DEVICES_ERRORS, SERVER_COM_FAILED))
    }
}

const getErrorTypesProcess = ({dispatch}) => next => action => {
    next(action)
    if (action.type === FETCH_DEVICES_ERROR_TYPES) {
        const params = {
            action: 'get_error_types',
        }
        dispatch(postRequest(HANDLE_ERRORS_URL, params, UPDATE_DEVICES_ERRORS, SERVER_COM_FAILED))
    }
}

const archiveErrorsProcess = ({dispatch}) => next => action => {
    next(action)
    if (action.type === ARCHIVE_DEVICE_ERRORS) {
        const params = {
            action: 'archive_device_errors',
            ...action.payload,
        }
        dispatch(postRequest(HANDLE_ERRORS_URL, params, UPDATE_DEVICES_ERRORS, SERVER_COM_FAILED))
    }
}

export const errorsMiddleware = [
    getErrorsProcess,
    getErrorTypesProcess,
    archiveErrorsProcess,
]