import {
    REPORT_APP_ERROR,
    REPORT_SERVER_ERROR,
    CLEAR_ALL_ERRORS,
    CLEAR_APP_ERRORS,
    UPDATE_DEVICES_ERRORS,
    UPDATE_DEVICES_ERROR_TYPES,
    SERVER_COM_FAILED,
    SET_UPDATE_IN_PROGRESS,
} from '../actions/errorActions'


const QUEUE_LIMIT = 10

const appErrors = {
    numOfErrors: 0,
    recentErrors: [],
}

const initialState = {
    updateInProgress: false,
    appErrors,
    serverErrors: {
        numOfErrors: 0,
        recentErrors: [],
    },
    devicesErrors: {
        errorTypes: {},
        errorInstances: [], // {deviceId: [errorInstancesArray], ...}
    },
}

function reportError(action, previousErrors) {
    const newErrors = [action.payload, ...previousErrors.recentErrors]
    if (newErrors.length > QUEUE_LIMIT) {
        newErrors.pop()
    }
    return {
        numOfErrors: previousErrors.numOfErrors + 1,
        recentErrors: newErrors,
    }
}

export function errorsReducer(state=initialState, action) {
    switch(action.type) {
        case REPORT_APP_ERROR:
            return {
                ...state,
                appErrors: reportError(action, state.appErrors),
            }
        case REPORT_SERVER_ERROR:
            const newServerErrors = [action.payload, ...state.serverErrors.recentErrors]
            if (newServerErrors.length > QUEUE_LIMIT) {
                newServerErrors.pop()
            }
            return {
                ...state,
                serverErrors: {
                    numOfErrors: state.serverErrors.numOfErrors + 1,
                    recentErrors: newServerErrors,
                }
            }
        case UPDATE_DEVICES_ERRORS:
            const {action: chosenAction} = action.payload
            if (chosenAction === 'get_for_device') {
                const {device, data} = action.payload
                return {
                    ...state,
                    updateInProgress: false,
                    devicesErrors: {
                        ...state.devicesErrors,
                        errorInstances: {...state.devicesErrors.errorInstances, [device]: data},
                    }
                }
            } else if (chosenAction === 'get_error_types') {
                const {data} = action.payload
                return {
                    ...state,
                    updateInProgress: false,
                    devicesErrors: {
                        ...state.devicesErrors,
                        errorTypes: data,
                    }
                }
            } else {
                return {
                    ...state,
                    updateInProgress: false,
                }
            }
        case SET_UPDATE_IN_PROGRESS:
            return {
                ...state,
                updateInProgress: true,
            }
        case SERVER_COM_FAILED:
            return {
                ...state,
                updateInProgress: false,
            }
        case CLEAR_APP_ERRORS:
            return {
                ...state,
                appErrors,
            }
        case CLEAR_ALL_ERRORS:
            return {
                ...state,
                appErrors: [],
                serverErrors: [],
            }
        default:
            return state
    }
}

export const selectErrorStore = state => state.errors
export const selectDeviceErrors = state => selectErrorStore(state).devicesErrors.errorInstances || []
export const selectDeviceErrorsById = id => state => selectDeviceErrors(state)[id]