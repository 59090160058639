import {
    getRequest,
    postRequest,
    deleteRequest,
    putRequest,
} from '../actions/apiActions'

import {selectShowDeviceDetails} from '../reducers/uiReducer'
import {UPDATE_COMMANDS} from '../actions/devicesActions'
import {UPDATE_GPS_POSITIONS} from '../actions/gpsPositionsActions'
import {UPDATE_GENERAL_SETTINGS} from '../actions/userActions'
import {selectServerUpdatesStore} from '../reducers/serverUpdatesReducer'

import {UPDATE_TRIPS} from '../actions/tripsActions'
import {UPDATE_DEVICES} from '../actions/devicesActions'
import {UPDATE_AREAS} from '../actions/areasActions'
import {
    START_UPDATE,
    getServerInfo,
    FETCH_INFO,
    DISPATCH_INFO_TO_SECTIONS,
    setUpdateInProgress,
    ERROR_GETTING_UPDATE,
    setUpdateResult,
} from '../actions/serverUpdatesActions'

const SERVER_INFO_URL = 'get_update_for_frontend'

const startUpdate = ({dispatch, getState}) => next => action => {
    next(action)
    if (action.type === START_UPDATE) {
        dispatch(setUpdateInProgress())

        dispatch(getServerInfo())
        // dispatch(getTrips())
        // dispatch(getAreas())
        // dispatch(getDevices())
        // dispatch(getDeviceReports())
    }
}

const getServerInfoProcess = ({dispatch, getState}) => next => action => {
    next(action)
    const state = getState()
    const {user} = state
    const userName = user.name
    const params = {
        data: {
            userName,
            user,
            commandsForDevice: selectShowDeviceDetails(state),
            updatesParams: selectServerUpdatesStore(state),
        }
    }
    if (action.type === FETCH_INFO) {
        dispatch(postRequest(SERVER_INFO_URL, params, DISPATCH_INFO_TO_SECTIONS, ERROR_GETTING_UPDATE))
    }
}

const dispatchInfoToSectionsProcess = ({dispatch}) => next => action => {
    next(action)
    if (action.type === DISPATCH_INFO_TO_SECTIONS) {
        dispatch(setUpdateResult(true, new Date(), null))
        if (action.payload.updateDate) {
            // alert(action.payload.updateDate)
        }
        const commandsForDevice = action.payload.commandsForDevice
        if (commandsForDevice && commandsForDevice['commands'] && commandsForDevice['commands'].length > 0) {
            dispatch({type: UPDATE_COMMANDS, payload: commandsForDevice})
        }
        const gpsLocations = action.payload.gpsLocations
        if (gpsLocations) {
            dispatch({type: UPDATE_GPS_POSITIONS, payload: gpsLocations})
        }
        const trips = action.payload.trips
        if (trips) {
            dispatch({type: UPDATE_TRIPS, payload: trips})
        }
        const areas = action.payload.areas
        if (areas) {
            dispatch({type: UPDATE_AREAS, payload: areas})
        }
        const devices = action.payload.devices
        if (devices) {
            dispatch({type: UPDATE_DEVICES, payload: devices})
        }
        const generalSettings = action.payload.settings
        if (generalSettings) {
            dispatch({type: UPDATE_GENERAL_SETTINGS, payload: generalSettings})
        }
    }
}

export const serverUpdatesMiddleware = [
    getServerInfoProcess,
    dispatchInfoToSectionsProcess,
    startUpdate,
]